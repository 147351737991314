import "./styles/App.scss";
import "./styles/Effects.scss";
import "./styles/Notification.scss";
import "./styles/Quests.scss";
import "./styles/UpIn.scss";
import "./styles/InputText.scss";
import "./styles/home/Form.scss";
import "./styles/home/notifications.scss";

import { BrowserRouter as Router, Route, Routes, BrowserRouter } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Signup } from "./components/AuthComponents/Signup";
import { Login } from "./components/AuthComponents/Login";
import { Close } from "./components/Close";
import { DashboardAdmin } from "./components/DashboardComponents/AdminComponents/DashboardAdmin";
import DashboardRoot from "./components/DashboardComponents/DashboardRoot";
import { AuthProvider } from "./components/Providers/AuthProvider";
import { CustomThemeProvider } from "./components/Providers/CustomThemeProvider";
import { FirebaseProvider } from "./components/Providers/FirebaseProvider";
import { LeaderboardProvider } from "./components/Providers/LeaderboardProvider";
import { MarketProvider } from "./components/Providers/MarketProvider";
import { NetworkProvider } from "./components/Providers/NetworkProvider";
import { PaymentProvider } from "./components/Providers/PaymentProvider";
import { SettingsProvider } from "./components/Providers/SettingsProvider";
import { SnackbarProvider } from "./components/Providers/SnackbarProvider";
import { StatsProvider } from "./components/Providers/StatsProvider";
import { UserProvider } from "./components/Providers/UserProvider";
import { Snackbars } from "./components/Snackbars";
import { AboutUs } from "./pages/home/AboutUs";
import { Landing } from "./pages/home/Landing";
import { GamesProviderImpl } from "./components/Providers/GamesProviderImpl";
import { PixelsProvider } from "./components/Providers/PixelsProvider";
import { CookiesPage } from "./pages/cookies/CookiesPage";
import { WalletProviderImpl } from "./components/Providers/WalletProvider";
import { DashboardMarket } from "./components/DashboardComponents/DashboardMarket";
import { Header } from "./components/HomepageComponents/Header";
import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions";
import { PrivacyPolicies } from "./pages/PrivacyPolicies/PrivacyPolicies";

function App(): JSX.Element {
	const showToast = (text: string | JSX.Element, type: string): void => {
		if (type === "Success") {
			toast.success(text);
		} else if (type === "Info") {
			toast(text);
		} else if (type === "Warning") {
			toast(<div className={"toast-notification"}>{text}</div>, {
				style: {
					backgroundColor: "black",
					color: "rgb(195, 147, 45)",
					border: "1px solid rgb(195, 147, 45)",
				},
				duration: 3000,
			});
		} else if (type === "Error") {
			toast(<div className={"toast-notification"}>{text}</div>, {
				style: {
					backgroundColor: "black",
					color: "red",
				},
				duration: 3000,
			});
		} else {
			toast(text);
		}
	};

	return (
		<>
			<Router>
				<SnackbarProvider>
					<WalletProviderImpl>
						<FirebaseProvider>
							<SettingsProvider>
								<NetworkProvider>
									<AuthProvider>
										<UserProvider>
											<LeaderboardProvider>
												<NetworkProvider>
													<PaymentProvider>
														<MarketProvider>
															<StatsProvider>
																<GamesProviderImpl>
																	<PixelsProvider>
																		<CustomThemeProvider>
																			<Toaster position="top-center" />
																			<Snackbars />
																			<Routes>
																				<Route path="/" element={<Landing showToast={showToast} />} />
																				<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
																				<Route path="/privacy-policies" element={<PrivacyPolicies />} />
																				<Route path="/about" element={<AboutUs />} />
																				<Route path="/login" element={<Login showToast={showToast} />} />
																				<Route path="/cookie policy" element={<CookiesPage />} />
																				<Route path="/signup" element={<Signup showToast={showToast} />} />
																				<Route
																					path="/market"
																					element={
																						<>
																							<Header />
																							<br />
																							<br />
																							<DashboardMarket />
																						</>
																					}
																				/>
																				<Route path="/dashboard/" element={<DashboardRoot showToast={showToast} />} />
																				<Route path="/dashboard/statsDashboard" element={<DashboardAdmin />} />
																				<Route
																					path="/unauthorized"
																					element={
																						<>
																							<h1>HTTPS ERROR 401: NOT AUTHORIZED </h1> <br />
																							<h4>
																								<a href="/">go back to the Homepage</a>
																							</h4>
																						</>
																					}
																				/>
																				<Route
																					path="/networkError"
																					element={
																						<>
																							<h1>HTTPS ERROR 500: INTERNAL SERVER ERROR </h1> <br />
																							<h4>
																								<a href="/">go back to the Homepage</a>
																							</h4>
																						</>
																					}
																				/>
																				<Route path="/close" element={<Close />} />
																				<Route
																					element={
																						<>
																							<h1>HTTPS ERROR 404: PAGE NOT FOUND </h1> <br />
																							<h4>
																								<a href="/">go back to the Home</a>
																							</h4>
																						</>
																					}
																				/>
																			</Routes>
																		</CustomThemeProvider>
																	</PixelsProvider>
																</GamesProviderImpl>
															</StatsProvider>
														</MarketProvider>
													</PaymentProvider>
												</NetworkProvider>
											</LeaderboardProvider>
										</UserProvider>
									</AuthProvider>
								</NetworkProvider>
							</SettingsProvider>
						</FirebaseProvider>
					</WalletProviderImpl>
				</SnackbarProvider>
			</Router>
		</>
	);
}

export default App;
