import { Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import line_about_us from "../../../assets/images/line_about_us.svg";
import line_about_us_mobile from "../../../assets/images/line_about_us_mobile.svg";

import "../../../styles/home/aboutUs/HowItWorks.scss";
import { useTranslation } from "react-i18next";

export const HowItWorks = () => {
	const { t } = useTranslation("landing");
	const [lineAnimation, setLineAnimation] = useState<boolean>(false);
	const textContainerRef = useRef(null);
	const containerRef = useRef(null);
	const [textToShow, setTextToShow] = useState<number>(0);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	const [heightLine, setHeightLine] = useState<number>(0);
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	// useEffect(() => {
	// 	const observer = new IntersectionObserver((entries) => {
	// 		entries.forEach((entry) => {
	// 			/**Make sure that the timeout time is equal to the animation time */
	// 			if (entry.target.id === "line-about-us-section" && windowDimensions.width > 700) {
	// 				if (entry.isIntersecting) {
	// 					if (!entry.target.classList.contains("line-about-us-section-animation")) {
	// 						entry.target.classList.add("line-about-us-section-animation");
	// 						setLineAnimation(true);
	// 						observer.unobserve(entry.target);
	// 					}
	// 				} else {
	// 					if (entry.target.classList.contains("line-about-us-section-animation")) {
	// 						entry.target.classList.remove("line-about-us-section-animation");
	// 						setLineAnimation(false);
	// 						observer.unobserve(entry.target);
	// 					}
	// 				}
	// 			}
	// 		});
	// 	});

	// 	if (textContainerRef.current) {
	// 		observer.observe(textContainerRef.current);
	// 	}

	// 	return () => {
	// 		if (textContainerRef.current) {
	// 			observer.unobserve(textContainerRef.current);
	// 		}
	// 	};
	// }, [lineAnimation]);
	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	// useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		(entries) => {
	// 			entries.forEach((entry) => {
	// 				/**Make sure that the timeout time is equal to the animation time */
	// 				if (entry.target.id === "content-how-it-works" && windowDimensions.width < 700) {
	// 					if (entry.isIntersecting) {
	// 						if (!entry.target.classList.contains("block-scrolling")) {
	// 							// entry.target.classList.add("block-scrolling");
	// 							document.getElementById("container-how-it-works").classList.add("block-scrolling-container");
	// 							// observer.unobserve(entry.target);
	// 						}
	// 					} else {
	// 						if (entry.target.classList.contains("block-scrolling")) {
	// 							// entry.target.classList.remove("block-scrolling");
	// 						}
	// 					}
	// 				}
	// 			});
	// 		},
	// 		{ threshold: 0.8 }
	// 	);
	// 	const handleScroll = () => {
	// 		const section = document.getElementById("container-how-it-works");

	// 		const rect = section.getBoundingClientRect();
	// 		setHeightLine(-rect.y / 2);
	// 		if (-rect.y > windowDimensions.height / 1.5) {
	// 			setTextToShow(1);
	// 		} else {
	// 			setTextToShow(0);
	// 		}
	// 	};
	// 	document.body.addEventListener("scroll", handleScroll);

	// 	if (containerRef.current) {
	// 		observer.observe(containerRef.current);
	// 	}

	// 	return () => {
	// 		if (containerRef.current) {
	// 			observer.unobserve(containerRef.current);
	// 		}
	// 		document.body.removeEventListener("scroll", handleScroll);
	// 	};
	// }, []);

	return (
		<>
			<Grid container sx={{ marginTop: { xs: 10, sm: 20 }, marginBottom: { xs: 0 }, minHeight: "auto" }}>
				<Grid item xs={11} sm={12} sx={{ marginBottom: { xs: 5, sm: 40 } }}>
					<HowItWorksShop />
				</Grid>
			</Grid>
		</>
	);
};

const HowItWorksShop = () => {
	const { t } = useTranslation("landing");
	return (
		<>
			<Grid container justifyContent="center" alignItems="center" rowGap={2}>
				<Grid item xs={11} xl={8}>
					<Typography variant="h6" textAlign="center" className="gradient-text-conatainer how-it-works-shop-text">
						{t("aboutus.how.it.works.title#1")}
						<span className="gradient-text"> {t("aboutus.how.it.works.title#2")}</span>
					</Typography>
				</Grid>
				<Grid item xs={11} xl={8}>
					<Typography variant="h3" textAlign="center" className="gradient-text-conatainer how-it-works-gitfcard-text">
						{t("aboutus.shop.title#1")}
						<span className="gradient-text"> {t("aboutus.shop.title#2")} </span>
						{t("aboutus.shop.title#3")}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
