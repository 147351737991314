import "../../styles/InputDropDown.scss";
import $ from "jquery";
import { MouseEvent, useEffect, useState } from "react";
import { InputDropDownOptions, InputDropDownProps } from "../../types/Input";

/** Component for InputDropDown (select)
 *  @arg {String} id The id for the DropDown component
 *  @arg {Boolean} blankItem Add an empty item at the top of the list when filled
 *  @arg {String} placeholder The placeholder for the DropDown component
 *  @arg {Array} options The options list inside the select. It must contains KEY and VALUE for each option
 *  @func onChange The onChange function
 *
 *  */
export const InputDropDown = (props: InputDropDownProps): JSX.Element => {
	const { id, value = "", blankItem = false, placeholder = "", options = [], onChange } = props;
	const [isOpen, setIsOpen] = useState(false);
	const [headerTitle, setHeaderTitle] = useState(value);

	const selectItem = (item: InputDropDownOptions, e: MouseEvent<HTMLButtonElement>): void => {
		const { value } = item;

		if (value === "") {
			$(`#${id}`).attr("data-empty", "true");
		} else {
			$(`#${id}`).attr("data-empty", "false");
		}

		setHeaderTitle(value);

		$(`#${id}`).attr("data-open", String(!isOpen));

		setIsOpen(false);

		if (onChange) {
			onChange(e);
		}
	};
	useEffect(() => {
		setHeaderTitle(value);
	});
	const toggleList = (e: MouseEvent<HTMLButtonElement>): void => {
		if (id) {
			$(`#${id}`).attr("data-open", String(!isOpen));

			setIsOpen(!isOpen);
		}
	};

	if (id && options.length > 0) {
		return (
			<>
				<div id={id} className="select-container">
					<button type="button" className="select-header" onClick={toggleList}>
						<div className="select-header-title">{headerTitle ? headerTitle : <div className="select-header-placeholder">{placeholder}</div>}</div>
						<div className="select-header-rectangle"></div>
					</button>
					{isOpen && (
						<div role="list" className="select-list">
							{blankItem ? headerTitle ? <button type="button" className="select-list-item item-blank" key={""} onClick={(e) => selectItem({ value: "", key: "", help: "" }, e)}></button> : <></> : <></>}
							{options.map(
								(item): JSX.Element => (
									<button type="button" className="select-list-item" key={item.key} value={item.value} onClick={(e) => selectItem(item, e)}>
										{item.help}
									</button>
								)
							)}
						</div>
					)}
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className="select-container">
					<p style={{ color: "#880946", paddingTop: "10px", paddingRight: "10px", paddingLeft: "10px" }}>Something went wrong...</p>
				</div>
			</>
		);
	}
};
