import { Avatar } from "@mui/material"

export const CircleImage = ({ src, alt, width, height, sx }: {
    src: string,
    alt: string,
    width?: number,
    height?: number,
    sx?: any
}) => {
    return (
        <Avatar
            alt={alt}
            src={src}
            sx={{
                width: width,
                height: height,
            }}
        />
    )
}