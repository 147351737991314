import { ThemeProvider } from "@mui/styles";
import { PaletteColorOptions, createTheme } from "@mui/material";
import { createContext } from "react";

export const CustomThemeContext = createContext<any>({});
export const CustomThemeProvider = (props: any): JSX.Element => {
	const children = props.children;

	const theme = createTheme({
		
	})

	return <CustomThemeContext.Provider value={{}}>
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	</CustomThemeContext.Provider>;
};
