import { Box, Card, Grid } from "@mui/material";
import styled from "styled-components";
import { useState, useContext } from "react";
import { Inventory } from "../../../../types/Payments";
import { AuthContext } from "../../../Providers/AuthProvider";
import estorm_plus from "../../../../assets/images/estorm_plus.svg";

import "../../../../styles/dashboard/market/Subscription.scss";

import { SubscriptionSubscribed } from "./SubscriptionSubscribed";

import { ReactComponent as EStormPlusGradient } from "../../../../assets/images/subscription_gradient_background.svg";
import { DialogConfirmBuySubscription } from "./DialogConfirmBuySubscription";
import { useTranslation } from "react-i18next";
import { UnauthorizedDialog } from "../../UnauthorizedDialog";

const StyledCard = styled(Card)`
	width: 70%;
	height: 100%;
	position: relative;
`;
const StyledButton = styled.button`
	width: 50%;
	height: 50px;
	text-align: center;
	align-self: end;
`;

export const Subscription = ({ subscriptionInfo }: { subscriptionInfo: Inventory.SubscriptionItem }) => {
	const { profile } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isUnauthorizedDialogOpen, setIsUnauthorizedDialogOpen] = useState<boolean>(false);

	const handleBuy = () => {
		if (profile === null) setIsUnauthorizedDialogOpen(true);
		else setIsDialogOpen(true);
	};

	return (
		<>
			<UnauthorizedDialog open={isUnauthorizedDialogOpen} handleClose={() => setIsUnauthorizedDialogOpen(false)} />
			<DialogConfirmBuySubscription open={isDialogOpen} handleClose={() => setIsDialogOpen(false)} subscriptionInfo={subscriptionInfo} />
			<StyledCard>
				{profile !== null && profile.isPremium ? (
					<Grid container sx={{ position: "relative" }}>
						<SubscriptionSubscribed />
					</Grid>
				) : (
					<>
						<Grid container height="100%" sx={{ position: "relative" }}>
							{/* ESTORM PLUS LOGO */}
							<Grid item xs={12} lg={6} display="flex" justifyContent="center" alignItems="center" position="relative" py={{ xs: 5, lg: 0 }}>
								<EStormPlusGradient width="80%" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
								<Box component="img" alt="estorm plus" src={estorm_plus} sx={{ zIndex: 1 }} />
							</Grid>

							{/* SUBSCRIPTION INFORMATION */}
							<Grid
								item
								xs={12}
								lg={6}
								height={{ xs: "auto", lg: "300px" }}
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								py={2}
								pr={3}
								gap={{ xs: 5, lg: 0 }}>
								<Box component="h2" fontWeight="bold" display={{ xs: "none", lg: "block" }}>
									eStorm <span className="subscription-gradient-text">Premium</span>
								</Box>
								<ul style={{ opacity: 0.8 }}>
									<li className="text-p">{t("market.subscription.card.guaranteed.daily.gem.distribution")}</li>
									<li className="text-p">{t("market.subscription.card.daily.monthly.quests")}</li>
									<li className="text-p">{t("market.subscription.card.charts.analytics")}</li>
									{/* <li className="text-p">{t("market.subscription.card.extra.lives.showdown")}</li>
									<li className="text-p">{t("market.subscription.card.no.ads")}</li> */}
								</ul>
								<span className="text-p">
									<span className="fw-bold">{t("market.subscription.card.duration.text#1")}</span>: {t("market.subscription.card.duration.text#2")}
								</span>
								<StyledButton onClick={handleBuy} type="button" className={`btn-no-fill text-p`}>
									<s style={{ color: "red" }}>120</s> {subscriptionInfo.priceInBolts} BOLTS
								</StyledButton>
							</Grid>
						</Grid>
					</>
				)}
			</StyledCard>
		</>
	);
};
