import { useContext, useEffect } from "react";
import { useState } from "react";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";
import { LeaderboardContext } from "../../../Providers/LeaderboardProvider";
import { AuthContext } from "../../../Providers/AuthProvider";
import { LeaderItem } from "./LeaderItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { LeaderboardDota } from "./Games/LeaderboardDota";
import { LeaderboardFortnite } from "./Games/LeaderboardFortnite";
import { LeaderboardButtons } from "./LeaderboardButtons";
import type { Swiper as SwiperType } from "swiper";
import $ from "jquery";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { LeaderboardProps } from "../../../../types/Props";
import { PlayerLeaderboardDota, PlayerLeaderboardFortnite } from "../../../../types/Leaderboard";
import { gamesInStorm } from "../../../../utilities/costants/genericCostants";

export const Leaderboard = (props: LeaderboardProps): JSX.Element => {
	const { profile } = useContext(AuthContext);
	const { myStorage } = useContext(FirebaseContext);
	const { standardLeaderboard, standardLiveLeaderboard } = useContext(LeaderboardContext);
	const [leaderboardToShow, setLeaderboardsToShow] = useState([] as PlayerLeaderboardDota[]);
	const [leaderboardToShowFortnite, setLeaderboardsToShowFortnite] = useState([] as PlayerLeaderboardFortnite[]);
	const style = props.style;
	const [my_swiper, set_my_swiper] = useState({} as SwiperType);
	const [indexOfSwiper, setIndexOfSwiper] = useState(0);

	useEffect(() => {
		if (props.isLiveLeaderboard) {
			loadLiveLeaderboard();
		} else {
			loadNormalLeaderboard();
		}
	}, [standardLeaderboard]);

	const loadLiveLeaderboard = async (): Promise<void> => {
		if (standardLiveLeaderboard.dota2 != undefined && standardLiveLeaderboard.fortnite != undefined) {
			try {
				setLeaderboardsToShow([]);
				setLeaderboardsToShowFortnite([]);

				standardLiveLeaderboard.dota2.map((p, i) => {
					setLeaderboardsToShow(
						(prevBox) =>
							[
								...prevBox,
								<LeaderItem player={p} image={p.imageUrl} username={p.username} key={i} index={i} game={gamesInStorm.dota} />,
							] as PlayerLeaderboardDota[]
					);
				});
				standardLiveLeaderboard.fortnite.map((p, i) => {
					setLeaderboardsToShowFortnite(
						(prevBox) =>
							[
								...prevBox,
								<LeaderItem player={p} image={p.imageUrl} username={p.username} key={i} index={i} game={gamesInStorm.fortnite} />,
							] as PlayerLeaderboardFortnite[]
					);
				});
			} catch (err) {
				console.log(err.message);
			}
		}
	};

	const loadNormalLeaderboard = async (): Promise<void> => {
		if (standardLeaderboard.dota2 != undefined && standardLeaderboard.fortnite != undefined) {
			try {
				setLeaderboardsToShow([]);
				setLeaderboardsToShowFortnite([]);

				standardLeaderboard.dota2.map((p, i) => {
					setLeaderboardsToShow(
						(prevBox) =>
							[
								...prevBox,
								<LeaderItem player={p} image={p.imageUrl} username={p.username} key={i} index={i} game={gamesInStorm.dota} />,
							] as PlayerLeaderboardDota[]
					);
				});
				standardLeaderboard.fortnite.map((p, i) => {
					setLeaderboardsToShowFortnite(
						(prevBox) =>
							[
								...prevBox,
								<LeaderItem player={p} image={p.imageUrl} username={p.username} key={i} index={i} game={gamesInStorm.fortnite} />,
							] as PlayerLeaderboardFortnite[]
					);
				});
			} catch (err) {
				console.log(err.message);
			}
		}
	};

	useEffect(() => {
		if (props.isLiveLeaderboard && !profile != null && profile.isPremium) {
			$("#leaderboard-container-true").css("filter", "blur(3px)");
		}
	}, []);

	const initSlide = (): 0 | 1 => {
		const currentFavouriteGame = profile.favouriteGame ?? gamesInStorm.dota;
		if (currentFavouriteGame === gamesInStorm.dota) {
			return 0;
		} else if (currentFavouriteGame === gamesInStorm.fortnite) {
			return 1;
		}
	};

	return (
		<>
			<div id={`leaderboard-container-${props.isLiveLeaderboard}`}>
				<LeaderboardButtons my_swiper={my_swiper} />
				<Swiper
					spaceBetween={30}
					navigation
					id="swiper-leaderboard"
					slidesPerView={1}
					onInit={(ev) => {
						set_my_swiper(ev);
					}}
					initialSlide={initSlide()}
					className="mySwiper"
					onSlideChange={(event) => {
						if (event.activeIndex === 0) {
							props.changeStatsRendered(gamesInStorm.dota);
						} else if (event.activeIndex === 1) {
							props.changeStatsRendered(gamesInStorm.fortnite);
						}
						setIndexOfSwiper(event.activeIndex);
					}}>
					<SwiperSlide
						className="next "
						onClick={() => {
							my_swiper.slidePrev();
						}}>
						<LeaderboardDota
							profile={profile}
							leaderboardToShow={leaderboardToShow}
							style={style}
							loadPlayers={props.loadPlayers}
							indexOfSwiper={indexOfSwiper}
							isLiveLeaderboard={props.isLiveLeaderboard}
						/>
					</SwiperSlide>
					<SwiperSlide
						className="next "
						onClick={() => {
							my_swiper.slideNext();
						}}>
						<LeaderboardFortnite
							profile={profile}
							leaderboardToShow={leaderboardToShowFortnite}
							style={style}
							loadPlayers={props.loadPlayers}
							indexOfSwiper={indexOfSwiper}
							isLiveLeaderboard={props.isLiveLeaderboard}
						/>
					</SwiperSlide>
				</Swiper>
			</div>
		</>
	);
};
