import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";

export const TutorialGameHubDialog = ({ cardRef }: {
    cardRef: any
}): JSX.Element => {
    const [dialog, setDialog] = useState(true);
	const { t } = useTranslation("dashboard");

    const closeDialog = (): void => {
        setDialog(false);
        cardRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    };

    return (
        <Dialog
            className="dialog-step-3"
            open={dialog}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="text-center">
                <p className="text-h3">{t("connect to storm")}</p>
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "rgba(255, 255, 255, 0.6)",
                        ":hover": {
                            color: "var(--error)",
                        },
                    }}
                >
                    <FontAwesomeIcon icon={faClose as IconProp} className="fa-lg" />
                </IconButton>
            </DialogTitle>
            <hr />
            <DialogContent>
                <div className="text-p">
                    <p>
                        {t("now click on the")} <span className="text-p-bold">{t("game icon")}</span> {t("and")}{" "}
                        <span className="text-p-bold">
                            {t("connect your")} {t("account")}
                        </span>
                    </p>
                    <p>{t("a new tab will appear")}</p>
                    <p>{t("last step to completed tutorial")}</p>
                </div>
            </DialogContent>
        </Dialog>
    );
};
