import { useContext } from "react";
import { useTranslation } from "react-i18next";

import "../../../styles/Footer.scss";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const FooterLanding = (): JSX.Element => {
	const { t } = useTranslation("dashboard");
	const { myStorageUrl } = useContext(FirebaseContext);

	return (
		<div className="footer-landing-container">
			<Grid container>
				<Grid item xs={12}>
					<img
						src={`${myStorageUrl}vectors/STORM_logo.svg`}
						style={{ paddingBottom: "45px", zIndex: 0, position: "relative" }}
						id="storm-logo-footer-landing"
						alt="image"
					/>
				</Grid>
				<Grid item xs={6} sx={{ zIndex: 10 }}>
					<Grid container>
						<Grid item xs={9} md={6}>
							<Stack spacing={1} paddingBottom={1}>
								<Link to="/privacy-policies" target="_blank" rel="noopener noreferrer" className="footerEmail" title="Privacy Policy">
									{t("privacy policy")}
								</Link>
								<Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="footerEmail" title="Terms of Service">
									{t("termsOfService")}
								</Link>
								<a target="_blank" rel="noreferrer" className="footerEmail" href="mailto:info@storm.co.gg" title="Support">
									{t("Support")}
								</a>
							</Stack>
						</Grid>
						<Grid item xs={6} md={6}>
							<Stack spacing={1}>
								<a className="footerEmail" href="/about" title="About us">
									{t("about us")}
								</a>
								<a className="footerEmail" href="/signup" title="Sign up">
									{t("Sign up")}
								</a>
								<a className="footerEmail" href="/login" title="Login">
									{t("login")}
								</a>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3} sx={{ marginBottom: "6em", zIndex: 10 }}>
					<Stack spacing={1}>
						<a href="https://www.facebook.com/StormP2E" target="_blank" rel="noreferrer" className="footerEmail">
							Facebook
						</a>
						<a href="https://discord.gg/jyYHXuTwpE" target="_blank" rel="noreferrer" className="footerEmail">
							Discord
						</a>
						<a href="https://www.linkedin.com/company/storm-play-to-earn/" target="_blank" rel="noreferrer" className="footerEmail">
							Linkedin
						</a>
					</Stack>
				</Grid>
				<Grid item xs={3} sx={{ marginBottom: "6em", zIndex: 10 }}>
					<a href="/cookie policy" target="_blank" rel="noreferrer" className="footerEmail">
						Cookies
					</a>
				</Grid>
				<Grid item xs={12} id="footer-text-iva">
					<Typography sx={{ paddingTop: "3em" }}>{t("footerIva")}</Typography>
				</Grid>
			</Grid>

			<hr className="border-line" />

			<Typography id="footer-text" variant="body2" align="center">
				{t("footer valve")}
			</Typography>
		</div>
	);
};
