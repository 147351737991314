import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomOutlinedButtonV1 = styled(Button)(({ theme }) => ({
	backgroundColor: "none",
	border: "1px solid white",
	color: theme.palette.common.white,
	borderRadius: 100,
	textTransform: "unset",

	// handle disabled state
	"&:disabled": {
		border: "1px solid white",
		color: theme.palette.common.white,
		opacity: 0.5,
	},
}));

export const OutlinedButtonV1 = ({
	content,
	onClick,
	sx,
	disabled = false,
}: {
	content: string;
	sx?: any;
	onClick: () => void;
	disabled?: boolean;
}) => {
	return (
		<CustomOutlinedButtonV1 sx={sx} onClick={onClick} variant="outlined" disabled={disabled}>
			<span className="text-cta">{content}</span>
		</CustomOutlinedButtonV1>
	);
};
