import { useState } from "react";
import { Grid } from "@mui/material";
import { HistoryPlacements } from "./HistoryPlacements";
import { Swiper, SwiperSlide } from "swiper/react";

import "../../../styles/Stats.scss";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { StatsButtons } from "./StatsButtons";
// import { Pagination as swiperpagination } from "swiper";
import type { Swiper as SwiperType } from "swiper";

export const Stats = (): JSX.Element => {
	const [my_swiper, set_my_swiper] = useState({} as SwiperType);
	const [disclamerGraphHoverOpen, setDisclamerGraphHoverOpen] = useState(false);
	const [indexOfSwiper, setIndexOfSwiper] = useState(0);
	
	const onChangeOfswiper = (activeIndex: number): void => {
		setIndexOfSwiper(activeIndex);
		setDisclamerGraphHoverOpen(false);
	};
	return (
		<>
			<Grid container sx={{ height: "100%" }} className="stats-placements-container">
				<Grid item xs={12}>
					<StatsButtons my_swiper={my_swiper} />
					<Swiper
						spaceBetween={30}
						navigation
						id="swiper-stats"
						slidesPerView={1}
						onInit={(ev) => {
							set_my_swiper(ev);
						}}
						initialSlide={0}
						className="stats-swiper"
						onSlideChange={(event) => {
							onChangeOfswiper(event.activeIndex);
						}}
					>
						<SwiperSlide
							className="next "
							onClick={() => {
								my_swiper.slidePrev();
							}}
						>
							<HistoryPlacements disclamerGraphHoverOpen={disclamerGraphHoverOpen} setDisclamerGraphHoverOpen={setDisclamerGraphHoverOpen} />
						</SwiperSlide>
					</Swiper>
				</Grid>
			</Grid>
		</>
	);
};
