import { Box, Stack } from "@mui/material";
import GradientTypographyBoxTitle from "../../Typographies/GradientTypographyBoxTitle";
import Slider from "react-slick";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import { CircleImage } from "../../Images/CircleImage";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { GamesContext } from "../../Providers/GamesProviderImpl";
import { useTranslation } from "react-i18next";
import { OutlinedButtonV1 } from "../../Buttons/CustomOutlinedButtonV1";
import { gamesInStorm } from "../../../utilities/costants/genericCostants";

const settings = {
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 3,
	arrows: false,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 833,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 599,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
			},
		},
		{
			breakpoint: 430,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

export const GamesConnectedBox = () => {
	const {
		profile: { gamesConnected },
	} = useContext(AuthContext);
	const { myStorageUrl } = useContext(FirebaseContext);
	const { getGameSrcByName } = useContext(GamesContext);
	const { changeActiveDashboard } = useContext(SettingsContext);
	const { t } = useTranslation("profilepage");

	return (
		<Stack spacing={1} direction={"column"} padding={"1em"} justifyContent={"center"}>
			<GradientTypographyBoxTitle
				sx={{
					marginBottom: "10px",
				}}
				content={t("connected games")}
				variant="h3"
			/>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}>
				{gamesConnected.length === 0 && (
					<OutlinedButtonV1 content={t("no.connected.games")} onClick={() => changeActiveDashboard("gamehub")} sx={{ fontSize: "10px" }} />
				)}
			</Box>

			{gamesConnected.length > 0 && (
				<Slider {...settings}>
					{gamesConnected.map((game, index) => {
						return <CircleImage width={105} height={105} key={index} src={`${myStorageUrl}${getGameSrcByName(game)}`} alt={`Image of ${game}`} />;
					})}
					<Box
						sx={{
							marginTop: "2em",
						}}>
						<AddGameButton />
					</Box>
				</Slider>
			)}
		</Stack>
	);
};

const AddGameButton = () => {
	const { changeActiveDashboard } = useContext(SettingsContext);
	const openGameHub = () => {
		changeActiveDashboard("gamehub");
	};
	return (
		<Box
			sx={{
				background: "linear-gradient(90deg, #1B1751 0.58%, #2F0A4C 97.84%)",
				borderRadius: "50%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				"&:hover": {
					background: "linear-gradient(90deg, #AB00C7 0.59%, #0075FF 97.84%)",
					cursor: "pointer",
				},
			}}
			width={40}
			height={40}
			onClick={openGameHub}>
			+
		</Box>
	);
};
