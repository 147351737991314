import { Box } from "@mui/material";
import { OutlinedButtonV2 } from "../../Buttons/CustomOutlinedButtonV2";
import { useContext } from "react";
import { AuthContext } from "../../Providers/AuthProvider";

export const LogoutButtonBox = () => {
	const { logout } = useContext(AuthContext);

	const handleLogOut = async (): Promise<void> => {
		await logout();
		window.location.href = "/";
	};

	return (
		<Box
			sx={{
				background: "black",
				borderRadius: "20px",
			}}
			textAlign={"center"}>
			<OutlinedButtonV2 onClick={handleLogOut} sx={{ fontSize: "10px", width: "60%", margin: "1.4em" }} content={"logout"} />
		</Box>
	);
};
