import { Box } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext } from "react";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { makeStyles } from "@mui/styles";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const styles = makeStyles({
	swiper: {},
	"@media (max-width: 900px)": {
		swiper: {
			display: "none",
		},
	},
});

export const SliderHomePage = (): JSX.Element => {
	const slides = [Slider1, Slider2, Slider3];
	const classes = styles();

	return (
		<Box
			position={"absolute"}
			style={{
				top: "0",
				left: "0",
				width: "100%",
				height: "100%",
				zIndex: "0",
			}}>
			<Swiper
				modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
				spaceBetween={0}
				slidesPerView={1}
				onSlideChange={() => {}}
				onSwiper={(swiper) => {}}
				navigation
				className={classes.swiper}
				autoplay={{ delay: 5000, disableOnInteraction: false }}
				pagination={{ clickable: true }}
				scrollbar={{ draggable: true }}>
				{slides.map((slide, index) => {
					return <SwiperSlide key={index}>{slide()}</SwiperSlide>;
				})}
			</Swiper>
		</Box>
	);
};

const Slider1 = () => {
	const { myStorageUrl } = useContext(FirebaseContext);

	return (
		<Box position={"relative"}>
			<img
				width={"100%"}
				style={{
					position: "relative",
				}}
				src={`${myStorageUrl}images/slider_2.png`}
				alt="banner"
			/>
		</Box>
	);
};

const Slider2 = () => {
	const { changeActiveDashboard } = useContext(SettingsContext);
	const { myStorageUrl } = useContext(FirebaseContext);
	return (
		<Box position={"relative"}>
			<img
				onClick={() => {
					changeActiveDashboard("market");
				}}
				width={"100%"}
				style={{
					position: "relative",
				}}
				src={`${myStorageUrl}images/slider_1.png`}
				alt="banner"
			/>
		</Box>
	);
};

const Slider3 = () => {
	const { myStorageUrl } = useContext(FirebaseContext);

	const goToDiscord = () => {
		window.open("https://discord.gg/jyYHXuTwpE", "_blank");
	};

	return (
		<Box position={"relative"}>
			<img
				onClick={goToDiscord}
				width={"100%"}
				style={{
					position: "relative",
				}}
				src={`${myStorageUrl}images/slider_3.png`}
				alt="banner"
			/>
		</Box>
	);
};
