import { useContext } from "react";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import {
	GoogleAuthProvider,
	UserCredential,
	browserSessionPersistence,
	getRedirectResult,
	setPersistence,
	signInWithPopup,
} from "firebase/auth";
import { AuthContext } from "../Providers/AuthProvider";
import { useSnackbar } from "../Providers/SnackbarProvider";
import { useTranslation } from "react-i18next";
import { GoogleSignInProps } from "../../types/Props";
import $ from "jquery";

export const GoogleSignInButton = (props: GoogleSignInProps) => {
	const { myStorageUrl, myAuth } = useContext(FirebaseContext);
	const { createUserAfterGoogleSignIn, validateCode, loading } = useContext(AuthContext);
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation("dashboard");

	if (myAuth) {
		getRedirectResult(myAuth)
			.then(async (userCredentials): Promise<void> => {
				if (!userCredentials.user) return;
				await handleSignInWihtAuthProvider(
					userCredentials.user.email,
					userCredentials.user.displayName,
					undefined,
					undefined,
					userCredentials.user.uid
				);
			})
			.catch((err): void => {
				if (err.message !== "Cannot read properties of null (reading 'user')") {
					console.error(err.message);
				}
			});

	}

	let signWithAuthProvider = async (): Promise<void> => {
		if (loading) return;

		let codice = $("#txtCodice").val() as string;
		let UsingCode = false;

		if (codice !== "" && codice !== undefined) {
			UsingCode = true;
		}

		try {
			if (UsingCode) {
				let { isCodeValid, message } = await validateCode(codice);
				if (!isCodeValid) {
					openSnackbar("Error", message, "error");
					return;
				}
			}
		} catch (e) {
			console.error(e);
		}

		const provider = new GoogleAuthProvider();
		provider.setCustomParameters({
			prompt: "consent",
		});
		await setPersistence(myAuth, browserSessionPersistence);
		let result: UserCredential | never;

		try {
			if (props.source === "signup" && !props.privacyAccepted) {
				openSnackbar("Warning", t("you must accept our privacy policy"), "warning");
				return;
			}
			result = (await signInWithPopup(myAuth, provider)) as UserCredential;
			await handleSignInWihtAuthProvider(
				result.user.email,
				result.user.displayName,
				codice,
				UsingCode,
				result.user.uid
			);
			window.location.href = "/dashboard";
		} catch (err) {
			console.error(err);
			switch (err.code) {
				case "auth/account-exists-with-different-credential":
					break;
				case "auth/popup-closed-by-user":
					break;
				default:
					openSnackbar("Error", err.code, "error");
					break;
			}
		}
	};

	let handleSignInWihtAuthProvider = async (
		email: string,
		displayName: string,
		promoCode: string,
		usingCode: boolean,
		uid: string
	): Promise<void> => {
		if (!email) {
			console.error("Email not defined");
			return;
		}
		if (!displayName) {
			console.error("Name of user not defined");
			return;
		}
		if (!uid) {
			console.error("uid is undefined");
			return;
		}
		try {
			const userCreated = await createUserAfterGoogleSignIn({
				email: email,
				username: displayName,
				promoCode: promoCode,
				UsingCode: usingCode,
				uid: uid,
			});
			if (userCreated) {
				openSnackbar(
					"Success",
					"Before proceeding with the creation of your storm account, we would like to let you know that our site uses cookies only and exclusively to make the user experience unique.",
					"info"
				);
			}
		} catch (err) {
			console.error(err);
			if (err.response !== undefined) {
				switch (err.response.data.message) {
					case "Email taken":
						openSnackbar("Success", "Welcome back, redirecting you...", "success");
						break;
					default:
						console.error(err);
				}
			}
		}
	};

	return (
		<img
			onClick={signWithAuthProvider}
			style={{ paddingLeft: "10px", cursor: "pointer" }}
			src={`${myStorageUrl}vectors/cta_google.svg`}
			alt="arrow"
		/>
	);
};
