import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { ChangeEvent, useContext } from "react";
import { Card, Grid, Switch, Tooltip } from "@mui/material";
import { AuthContext } from "../../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { PulseDiv } from "../../Tutorial/Tutorial";
import { useRef } from "react";
import { UserContext } from "../../Providers/UserProvider";
import { DashboardGameHubProps } from "../../../types/Props";
import { gamesInStorm } from "../../../utilities/costants/genericCostants";
import { FooterLanding } from "../../home/landing/FooterLanding";
import { TutorialGameHubDialog } from "./TutorialGameHubDialog";

export const DashboardGameHub = (props: DashboardGameHubProps): JSX.Element => {
	const { epicGamesUrl } = useContext(SettingsContext);
	const { profile, connectSteam, sendVerification } = useContext(AuthContext);
	const { myStorageUrl } = useContext(FirebaseContext);
	const { t } = useTranslation("dashboard");
	const { changeFavouriteGame } = useContext(UserContext);

	const cardRef = useRef(null);

	const games = [
		{
			name: gamesInStorm.dota,
			color: "#B22A23",
			onClick: connectDota,
			img: `${myStorageUrl}images/dota2logo.png`,
		},
		{
			name: gamesInStorm.fortnite,
			color: "#7120A0",
			onClick: redirectEpicGames,
			img: `${myStorageUrl}images/fortnite-logo.png`,
		},
	];

	async function connectDota(): Promise<void> {
		if (profile.emailVerified) {
			if (profile.steamId === "" || profile.steamId === undefined) {
				props.showToast(
					<>
						<p className="text-p">{t("dota2 must be public")}</p>
						<p>
							{t("visit this")}
							<a className="" href="https://help.steampowered.com/en/faqs/view/588C-C67D-0251-C276" target="_blank">
								{t("page")}
							</a>
							{t("find out how")}
						</p>
						<a className="btn-fill text-center" onClick={handleSteamRegistration}>
							{t("understood")}
						</a>
					</>,
					"Warning"
				);
			} else {
				props.showToast(t("already connected dota2"), "Warning");
			}
		} else {
			props.showToast(
				<>
					<p>{t("not verified?")}</p>
					<div className="verification-email">
						<p>{t("check email")}</p>
						<small>{t("or")}</small>
						<br />
						<button className="btn btn-verification" type={"button"} onClick={handleSendEmailVerification}>
							{t("send verification email")}
						</button>
					</div>
				</>,
				"Warning"
			);
		}
	}
	const handleEpicRegistration = () => {
		try {
			const url = epicGamesUrl();

			window.open(url);
		} catch (error) {
			console.error(error);
		}
	};

	function redirectEpicGames(): void {
		if (profile.emailVerified) {
			if (profile.epicId === "" || profile.epicId === undefined) {
				props.showToast(
					<>
						<p className="text-p">{t("fortnite must be public")}</p>
						<p>
							{t("visit this")}
							<a className="" href="https://fortnitetracker.com/article/990/how-to-make-your-fortnite-stats-public" target="_blank">
								{t("page")}
							</a>
							{t("find out how")}
						</p>
						<a className="btn-fill text-center" onClick={handleEpicRegistration}>
							{t("understood")}
						</a>
					</>,
					"Warning"
				);
			} else {
				props.showToast(t("already connected epic"), "Warning");
			}
		} else {
			props.showToast(
				<>
					<Grid display={"flex"} flexDirection={"column"}>
						<div className="verification-email">
							<p>{t("not verified?")}</p>
							<div className="verification-email">
								<p>{t("check email")}</p>
								<small>{t("or")}</small>
								<br />
								<button className="btn btn-verification" type={"button"} onClick={handleSendEmailVerification}>
									{t("send verification email")}
								</button>
							</div>
						</div>
					</Grid>
				</>,
				"Warning"
			);
		}
	}

	const handleSteamRegistration = async (): Promise<void> => {
		try {
			let steamHref = await connectSteam();
			if (steamHref != null) window.open(steamHref);
			else props.showToast(steamHref, "Error");
		} catch (err) {
			console.error(err);
			props.showToast(err.message, "Error");
		}
	};

	const handleSendEmailVerification = async (): Promise<void> => {
		if (profile.emailVerified) {
			props.showToast(t("account already verified"), "Info");
			return;
		}
		props.showToast(`${t("mail sent to")} ${profile.email}`, "Success");
		await sendVerification();
	};

	const handleChangeGame = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		if (event === undefined) {
			console.error(`Event is undefined in handleChangeGame`);
			return;
		}
		let returnMessage = "";
		let game = event.target.value;
		let checked = event.target.checked;

		if (!checked) {
			game = "";
		}

		try {
			returnMessage = await changeFavouriteGame(game);
		} catch (error) {
			console.error(error);
		}

		props.showToast(returnMessage, "Info");
	};

	return (
		<>
			{props.isTutorial && <TutorialGameHubDialog cardRef={cardRef} />}
			<div className="main">
				<div className="game-hub">
					<h1>{t("game hub")}</h1>

					<Tabs className="game-tabs">
						<TabList>
							<Tab>{t("catalog")}</Tab>
						</TabList>
						<TabPanel
							style={{
								position: "relative",
								zIndex: "101",
							}}>
							{profile.steamId === "" || profile.steamId === undefined ? (
								<>
									<h3>{t("connect your accounts")}</h3>
								</>
							) : (
								<>
									<h3>{t("games connected to storm")}</h3>
								</>
							)}

							<Grid container alignItems="center" justifyContent="center" columnGap={5} rowGap={5} ref={cardRef}>
								{games.map(({ color, onClick, img, name }) => {
									return (
										<Grid item xs={8} sm={4} md={2} key={name}>
											<PulseDiv pulse={props.isTutorial} color={color}>
												<Card
													variant="outlined"
													sx={{
														cursor: "pointer",
														width: "100% !important",
														"& img": {
															width: "70%",
															borderRadius: "100%",
														},
														"& span": {
															display: "block",
															margin: "20px auto",
														},
														"& span.favourite-game": {
															margin: "0 auto 20px auto",
														},
														"& span.favourite-game span": {
															margin: "auto",
														},
													}}>
													<div
														style={{
															borderRadius: "20px",
															padding: "30px",
														}}
														onClick={onClick}>
														<img src={img} alt={`${name} Logo`} />
														<span className="text-p-bold">{t(name)}</span>
													</div>
													{profile.gamesConnected.includes(name) && (
														<Tooltip title={t("gamehub favourite game")}>
															<span className="text-p-bold favourite-game">
																Favourite game:{" "}
																<Switch
																	color={"secondary"}
																	checked={profile.favouriteGame === name}
																	value={name}
																	onChange={async (event) => await handleChangeGame(event)}
																/>
															</span>
														</Tooltip>
													)}
												</Card>
											</PulseDiv>
											<br />
										</Grid>
									);
								})}
							</Grid>
						</TabPanel>
					</Tabs>
				</div>
			</div>

			<div style={{ position: "relative", marginBottom: "2em" }}>
				<FooterLanding />
			</div>
		</>
	);
};
