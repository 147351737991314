import { MouseEvent, useContext, useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import $ from "jquery";
import { InputDropDown } from "../Input/InputDropDown";
import { SettingsContext } from "../Providers/SettingsProvider";
import "../../styles/Header.scss";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../Providers/FirebaseProvider";

export const Header = (): JSX.Element => {
	const [menuOpen, setMenuOpen] = useState(false);
	const { myStorageUrl } = useContext(FirebaseContext);
	const { changeLanguage, language } = useContext(SettingsContext);
	const { t } = useTranslation("dashboard");

	function changeLanguageOnSelect(event: MouseEvent<HTMLButtonElement>): void {
		window.localStorage.setItem("language", event.currentTarget.value);
		changeLanguage(event.currentTarget.value);
	}

	function disableScroll(): void {
		if (!menuOpen) {
			document.body.classList.add("stop-scrolling");
			setMenuOpen(true);

			$(".header .header-dropdown-first ").removeClass("close-menu");
			$(".header .header-dropdown-first ").addClass("open-menu");

			$(".header .header-dropdown-second ").removeClass("close-menu");
			$(".header .header-dropdown-second ").addClass("open-menu");

			$("#header-logo").addClass("open-menu-logo");

			$(".header").addClass("header-menu-open");
			$(".header").removeClass("header-menu-close");

			$(".header .container-element-no-drop").removeClass("close-menu");

			$("#page-container").addClass("blur");
		} else {
			document.body.classList.remove("stop-scrolling");
			setMenuOpen(false);

			$("#header-logo").removeClass("open-menu-logo");

			$(".header .header-dropdown-first ").removeClass("open-menu");
			$(".header .header-dropdown-first ").addClass("close-menu");

			$(".header .header-dropdown-second ").removeClass("open-menu");
			$(".header .header-dropdown-second ").addClass("close-menu");

			$(".header .container-element-no-drop").removeClass("open-menu");
			$(".header .container-element-no-drop").addClass("close-menu");

			$(".header").removeClass("header-menu-open");
			$(".header").addClass("header-menu-close");

			$("#page-container").removeClass("blur");
		}
	}

	window.addEventListener("resize", handleWindowResize);

	function handleWindowResize(): void {
		if (window.innerWidth > 767.5 && menuOpen) {
			disableScroll();
		}
	}

	return (
		<>
			<div className="header-container">
				<Navbar
					collapseOnSelect
					expand="md"
					bg="transparent"
					variant="dark"
					className="header"
					id="head"
					expanded={menuOpen}
					onToggle={disableScroll}>
					<Container className="navbar-container">
						<div className="container-element-no-drop">
							<Navbar.Brand href="/">
								<div className="col-header-logo ">
									<img src={`${myStorageUrl}vectors/STORM_logo.svg`} id="header-logo"></img>
								</div>
							</Navbar.Brand>
							<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						</div>
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="container-dropdown">
								<div className="header-dropdown-first">
									<a href="/" className="link-header text-cta" onClick={() => {}}>
										{t("home")}
									</a>
									<a href="./about" className="link-header text-cta" onClick={() => {}}>
										{t("about us")}
									</a>
									<a href="./market" className="link-header text-cta" onClick={() => {}}>
										{t("market")}
									</a>
									<a href="./login" className="link-header text-cta" onClick={() => {}}>
										{t("login")}
									</a>
									<a href="./signup" className="link-header text-cta" onClick={() => {}}>
										{t("sign up")}
									</a>

									<Nav.Item className="header-language-desktop">
										<InputDropDown
											value={language}
											id="selectLanguageHeader"
											options={[
												{
													key: "en",
													value: "en",
													help: "en",
												},
												{
													key: "it",
													value: "it",
													help: "it",
												},
											]}
											onChange={changeLanguageOnSelect}
										/>
									</Nav.Item>
								</div>
								<div className="header-dropdown-second">
									<Nav.Item className="menu-footer">
										<InputDropDown
											value={language}
											id="selectLanguageHeader"
											options={[
												{
													key: "en",
													value: "en",
													help: "en",
												},
												{
													key: "it",
													value: "it",
													help: "it",
												},
											]}
											onChange={changeLanguageOnSelect}
										/>
										<div className="menu-footer-icon">
											<a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
												<img src={`${myStorageUrl}vectors/facebookico.svg`} id="facebookico-header" className="social-media-img" alt="facebookico" />
											</a>
											<a href="https://discord.gg/jyYHXuTwpE" target="_blank" rel="noreferrer">
												<img src={`${myStorageUrl}vectors/discordico.svg`} id="discordico-header" className="social-media-img" alt="discordico" />
											</a>
											<a href="https://t.me/+LQCQR7raHMgwMWI0" target="_blank" rel="noreferrer">
												<img
													src={`${myStorageUrl}vectors/telegramico.svg`}
													id="telegramico-header"
													className="social-media-img telegram-img"
													alt="telegramico"
												/>
											</a>
										</div>
									</Nav.Item>
								</div>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		</>
	);
};
