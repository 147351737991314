import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { FirebaseContext } from "./FirebaseProvider";
import { FirebaseProviderData, MarketProviderData } from "../../types/Providers";
import { createContext, useContext, useEffect, useState } from "react";

import { AuthContext } from "./AuthProvider";
import { AuthProviderData } from "../../types/Providers";

import { SubscriptionInfo } from "../../types/Market";
import { Inventory } from "../../types/Payments";
import { NetworkContext } from "./NetworkProvider";
import toast from "react-hot-toast";
import { didUserPayAnItemFullPriceAPI, PaymentsAPI } from "../../utilities/network/api";
import { DidUserPayAnItemFullPriceAPIResponse, RewardItemPurchaseAPIResponse } from "../../types/APIResponse";
import { useTranslation } from "react-i18next";

export const MarketContext = createContext<MarketProviderData | null>(null);

export const MarketProvider = (props): JSX.Element => {
	const children = props.children;
	const { t: tDashboard } = useTranslation("dashboard");
	const { t: tResponse } = useTranslation("responses");

	const [giftCards, setGiftCards] = useState<{ [country: string]: number[] }>();
	const [subscriptionInfo, setSubscriptionInfo] = useState({} as SubscriptionInfo);
	const [canUserApplyDiscount, setCanUserApplyDiscount] = useState(false);
	const giftcardsAvailable = [100, 200, 400];
	const VAT = 22;
	const [rates, setRates] = useState({} as Map<string, number>);
	const { makePost, makeGet } = useContext(NetworkContext);
	const oneBoltInCents = 5;

	const categories = {
		GEMS_PACK: "gemsPack",
		GIFT_CARDS: "giftCards",
		REWARDS: "rewards",
	};

	const { profile } = useContext(AuthContext) as AuthProviderData;
	const { myFS } = useContext(FirebaseContext) as FirebaseProviderData;

	const getExchangeRates = async (): Promise<void> => {
		try {
			if (profile == null || profile == undefined) return;
			const querySnapshot = await getDocs(collection(myFS, "exchangeRates"));
			let rates = new Map();
			querySnapshot.forEach((doc) => {
				Object.keys(doc.data()).map((keys) => {
					if (keys === "lastcheck") return;
					rates.set(keys as string, doc.data()[keys]);
				});
				setRates(rates);
			});
		} catch (err) {
			console.log(err);
		}
	};

	const fetchGiftCard = async (): Promise<void> => {
		const items = await getItemsFromDB();

		const giftCards = {} as { [country: string]: number[] };

		Object.entries(items.giftCards.amazon).forEach(([country, { amount }]) => {
			if (amount) giftCards[country] = amount;
		});

		setGiftCards(giftCards);
	};

	useEffect(() => {
		if (profile != null && profile.idToken != null) {
			getExchangeRates();
			fetchGiftCard();
			fetchSubscription();
			didUserPayAnItemFullPrice();
		}
	}, [profile?.idToken]);

	const fetchSubscription = async (): Promise<void> => {
		const docRef = doc(myFS, "inventory/subscription");
		const querySnapSubscription = await getDoc(docRef);
		setSubscriptionInfo(querySnapSubscription.data() as SubscriptionInfo);
	};

	const getItemsFromDB = async (): Promise<Inventory.Items> => {
		return (await getDoc(doc(myFS, "inventory/items"))).data() as Inventory.Items;
	};

	const buySubscriptionWithBolts = async (): Promise<void> => {
		const res = await makePost(PaymentsAPI.subscription, {
			_token: `Bearer ${profile.idToken}`,
		});
		if (res.status === 200) {
			toast.success(res.data.message);
		} else {
			toast.error(res.data.message);
		}
	};

	const didUserPayAnItemFullPrice = async () => {
		if (profile == null || profile.idToken == null) return false;

		const res = await makeGet<DidUserPayAnItemFullPriceAPIResponse>(`${didUserPayAnItemFullPriceAPI}?_token=${profile.idToken}`);

		if (res.status === 200) {
			setCanUserApplyDiscount(res.data.success);
			return res.data.success;
		} else {
			toast.error(tResponse(res.data.i18nKey));
			setCanUserApplyDiscount(false);
			return false;
		}
	};

	const marketProviderData: MarketProviderData = {
		giftCards,
		rates,
		oneBoltInCents,
		subscriptionInfo,
		giftcardsAvailable,
		getItemsFromDB,
		buySubscriptionWithBolts,
		categories,
		canUserApplyDiscount,
		setCanUserApplyDiscount,
		VAT
	};

	return <MarketContext.Provider value={marketProviderData}>{children}</MarketContext.Provider>;
};
