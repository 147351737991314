import { Box, Pagination } from "@mui/material";
import { useContext, useEffect } from "react";
import { LeaderboardContext } from "../../../Providers/LeaderboardProvider";
import { PaginationLeaderboardProps } from "../../../../types/Props";

export const PaginationLeaderboard = (props: PaginationLeaderboardProps): JSX.Element => {
	const { numberOfPlayers, playersPerPage, style, loadPlayers, game, isLiveLeaderboard, setIsLoadingPlayers } = props;

	const { currentPageInLeaderboardDota, setCurrentPageInLeaderboardDota, setCurrentPageInLiveLeaderboardDota } =
		useContext(LeaderboardContext);

	const handleChangePage = async (startingPos, pageNumber): Promise<void> => {
		setIsLoadingPlayers(true);
		if (isLiveLeaderboard) {
			setCurrentPageInLiveLeaderboardDota(pageNumber);
		} else {
			setCurrentPageInLeaderboardDota(pageNumber);
		}
		await loadPlayers(startingPos, game, isLiveLeaderboard);
		setTimeout(() => setIsLoadingPlayers(false), 1000);
	};

	const getCount = () => {
		if (numberOfPlayers % playersPerPage === 0) {
			return numberOfPlayers / playersPerPage;
		} else {
			return Math.floor(numberOfPlayers / playersPerPage) + 1;
		}
	}


	return (
		<div className={style().root}>
			<Box component="div" sx={{ display: { xs: "none", md: "block" } }}>
				<Pagination
					count={getCount()}
					onChange={async (event, pageNumber) =>
						await handleChangePage(playersPerPage * pageNumber - playersPerPage - 1, pageNumber)
					}
					siblingCount={0}
					defaultPage={currentPageInLeaderboardDota}
					color="secondary"
					classes={{ ul: style().root }}
					variant="text"
				/>
			</Box>
			<Box sx={{ display: { xs: "block", md: "none" } }}>
				<Pagination
					size="small"
					siblingCount={0}
					defaultPage={currentPageInLeaderboardDota}
					count={getCount()}
					onChange={async (event, pageNumber) =>
						await handleChangePage(playersPerPage * pageNumber - playersPerPage - 1, pageNumber)
					}
					color="secondary"
					classes={{ ul: style().root }}
					variant="text"
				/>
			</Box>
		</div>
	);
};
