import { useContext, useEffect, useState } from "react";
import { collection, doc, getDoc, getCountFromServer, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { AuthContext } from "../../Providers/AuthProvider";
import { UserContext } from "../../Providers/UserProvider";

export const DashboardStats = (): JSX.Element => {
	const [show, setShow] = useState(false);
	const [influencerEmail, setInfluencerEmail] = useState<string>("");
	const [total, setTotal] = useState(0);
	const [steam, setSteam] = useState(0);
	const [epic, setEpic] = useState(0);

	const { myFS } = useContext(FirebaseContext);
	const { profile } = useContext(AuthContext);
	const { handleUserPromoCode } = useContext(UserContext);

	const handleAddPromoCodeToUser = async () => {
		await handleUserPromoCode(influencerEmail, "add");
	};

	const handleDisablePromoCodeToUser = async () => {
		await handleUserPromoCode(influencerEmail, "disable");
	};

	const handleEnablePromoCodeToUser = async () => {
		await handleUserPromoCode(influencerEmail, "enable");
	};

	const handleGetStats = async () => {
		const collRef = collection(myFS, "users");
		const epicQuery = query(collRef, where("epicId", "!=", ""));
		const steamQuery = query(collRef, where("steamId", "!=", ""));

		const allSnap = await getCountFromServer(collRef);
		const epicSnap = await getCountFromServer(epicQuery);
		const steamSnap = await getCountFromServer(steamQuery);

		setEpic(epicSnap.data().count);
		setSteam(steamSnap.data().count);
		setTotal(allSnap.data().count);
	};

	useEffect(() => {
		handleGetStats();
	}, [profile?.idToken, myFS]);

	return (
		<>
			<button type="button" className="btn-no-fill mt-5" style={{ width: "20%" }} onClick={() => setShow(!show)}>
				<h3>{show ? "Hide" : "Show"} stats</h3>
			</button>
			<div style={{ display: show ? "block" : "none" }}>
				<h1>Stats</h1>
				<p>Total number of players: {total}</p>
				<p>Total number of players with Steam connected: {steam}</p>
				<p>Total number of players with Epic connected: {epic}</p>
				<input type="text" onChange={(event) => setInfluencerEmail(event.target.value)} placeholder="Insert email"></input>
				<button type="button" onClick={handleAddPromoCodeToUser} style={{ background: "green", color: "white" }}>
					Add promo code to user
				</button>
				<button type="button" onClick={handleDisablePromoCodeToUser} style={{ background: "red", color: "white" }}>
					Disabled user's promo code
				</button>
				<button type="button" onClick={handleEnablePromoCodeToUser}>
					Re-enable user's promo code
				</button>
			</div>
		</>
	);
};
