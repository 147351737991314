import { Container, Nav, Navbar } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SettingsContext } from "./Providers/SettingsProvider";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "./Providers/FirebaseProvider";
import { PulseDiv } from "./Tutorial/Tutorial";
import { Box, Grid } from "@mui/material";
import { GamerBox } from "./DashboardComponents/GamerBox";
import { AuthContext } from "./Providers/AuthProvider";
import { Notifications } from "./Notifications";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { possibleUserRole } from "../utilities/costants/userStatus";
import { NavbarStormProps } from "../types/Props";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import PersonIcon from '@mui/icons-material/Person';

export const NavbarStorm = (props: NavbarStormProps): JSX.Element => {
	const [notificationOpen, setNotificationOpen] = useState(false);
	const { activeDashboard, changeActiveDashboard } = useContext(SettingsContext);
	const { profile, notifications } = useContext(AuthContext);
	const [isAdmin, setIsAdmin] = useState(false);
	const { t } = useTranslation("dashboard");
	const { myStorageUrl } = useContext(FirebaseContext);

	const activeDashboardDotAnimation = () => {
		let id = "#nav-link-home";
		switch (activeDashboard) {
			case "home":
				id = "#nav-link-home";
				break;
			case "gamehub":
				id = "#nav-link-gamehub";
				break;
			case "market":
				id = "#nav-link-market";
				break;
			case "profile":
				id = "#nav-link-settings";
				break;
			case "statsDashboard":
				id = "#nav-link-stats";
				break;
		}

		if (id.length === 0) return;
		let widthElement: number;
		let positionElement: JQuery.Coordinates;
		let paddingLeft: number;
		if (id === "#nav-link-settings") {
			widthElement = $(id).find("path")[0].getBoundingClientRect().width;
			positionElement = $(id).find(".navbar-element-content").position();
			paddingLeft = Number($(id).find("svg").css("padding-left").slice(0, -2));
		} else {
			widthElement = $(id).find(".navbar-element-content").width();
			positionElement = $(id).find(".navbar-element-content").position();
			paddingLeft = Number($(id).find(".navbar-element-content").css("padding-left").slice(0, -2));
		}

		$("#underline-element").animate(
			{
				left: `${6+ paddingLeft + positionElement.left + widthElement / 2}px`,
			},
			0.3
		);
	};
	useEffect(() => {
		activeDashboardDotAnimation();
	}, [activeDashboard]);
	useEffect(() => {
		activeDashboardDotAnimation();
	}, []);

	function changePage(id: string): void {
		$(".page-selected").removeClass("page-selected");
		$(`.nav-link-${id}`).addClass("page-selected");
		changeActiveDashboard(id);
	}

	const isMarketDisabled = () => {
		const env = process.env.REACT_APP_ENV;
		let disabled = true;

		if (env.includes("DEV")) {
			disabled = false;
		} else {
			if (isAdmin) {
				disabled = true;
			} else {
				disabled = false;
			}
		}
		if (props.isTutorial) disabled = true;
		return false;
	};
	const toggleDrawer =
		(open): ((event: any) => void) =>
			(event): void => {
				if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
					return;
				}
				setNotificationOpen(open);
			};
	const theme = createTheme({
		palette: {
			primary: {
				main: "#ffffff",
				contrastText: "#fff",
			},
		},
	});

	useEffect(() => {
		if (profile != null && profile.role != null) {
			setIsAdmin(profile.role == possibleUserRole.admin);
		}
	}, [profile]);

	return (
		<>
			<Navbar
				collapseOnSelect
				expand="lg"
				variant="dark"
				id={"nav-bar-storm"}
				style={{ zIndex: props.isTutorial ? "101" : "1", position: "relative" }}
				className="navbar-header-desktop"
			>
				<Container id={"dashboard-header-container"}>
					<Grid
						container
						justifyContent={"space-between"}
						direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
						columns={16}
					>
						<Grid item sm={16} md={6} lg={6}>
							<Grid container>
								<Grid item xs={12} md={12}>
									{profile && (
										<GamerBox />
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={16} md={10} lg={10} className={"navbar-content"}>
							<img id={"logo-navbar-collapse"} src={`${myStorageUrl}vectors/STORM_logo.svg`} className="dashboard-logo" alt="logo" />
							<Navbar.Toggle aria-controls="responsive-navbar-nav" className=" navbar-icon" />
							<Navbar.Collapse id="responsive-navbar-nav">
								<div className="navbar-icon-container">
									<img id={"logo-navbar"} src={`${myStorageUrl}vectors/STORM_logo.svg`} className="dashboard-logo " alt="logo" />
								</div>

								<Nav className="me-auto"></Nav>
								<Box
									component={Nav}
									display={{ lg: "flex" }}
									sx={{ position: "relative" }}
									justifyContent={{ lg: "center", xs: "start" }}
									alignItems={{ lg: "center", xs: "start" }}
								>
									<div id="underline-element"></div>
									<Nav.Link
										active={activeDashboard == "home"}
										onClick={() => changeActiveDashboard("home")}
										id={"nav-link-home"}
										disabled={props.isTutorial}
									>
										<img
											src={`${myStorageUrl}vectors/dashico.svg`}
											id="dashboard-icon"
											className="tab-image navbar-icon"
											alt="logo"
										/>
										<label className={"text-on-navbar navbar-element-content"}>{t("dashboard")}</label>
									</Nav.Link>

									<Nav.Link
										active={activeDashboard == "gamehub"}
										onClick={() => changeActiveDashboard("gamehub")}
										id="nav-link-gamehub"
									>
										<PulseDiv pulse={props.isTutorial} color="white">
											<img
												src={`${myStorageUrl}vectors/gameico.svg`}
												id="gamehub-icon"
												className="tab-image navbar-icon"
												alt="logo"
											/>
											<label className={"text-on-navbar navbar-element-content"}>{t("game hub")}</label>
										</PulseDiv>
									</Nav.Link>

									<Nav.Link onClick={() => changeActiveDashboard("market")} id={"nav-link-market"} disabled={isMarketDisabled()}>
										<img
											src={`${myStorageUrl}vectors/markico.svg`}
											id="market-icon"
											className="tab-image navbar-icon"
											alt="logo"
										/>
										<label className={"text-on-navbar navbar-element-content"}>{t("market")}</label>
									</Nav.Link>
									<div className="notification-header transparent-background">
										<Notifications />
										<div className="notification-half-filler transparent-background"></div>
									</div>

									<Nav.Link
										active={activeDashboard == "profile"}
										onClick={() => changeActiveDashboard("profile")}
										id={"nav-link-settings"}
										disabled={props.isTutorial}
									>
										<div className="navbar-element-content">
											<PersonIcon sx={{
												fontSize: '3em'
											}} />
										</div>
									</Nav.Link>

									{isAdmin && (
										<Nav.Link
											active={activeDashboard == "statsDashboard"}
											onClick={() => changeActiveDashboard("statsDashboard")}
											id={"nav-link-stats"}
											disabled={props.isTutorial}
										>
											<img
												src={`${myStorageUrl}images/serverStats.png`}
												id={"stats-icon-navbar"}
												className="tab-image navbar-element-content"
												alt="stats"
											/>
										</Nav.Link>
									)}
								</Box>
							</Navbar.Collapse>
						</Grid>
					</Grid>
				</Container>
			</Navbar>

			<div className="navbar-header-mobile">
				{profile && (
					<GamerBox />
				)}
				<div className="notification-header transparent-background">
					<React.Fragment key={"right"}>
						<ThemeProvider theme={theme}>
							<Badge color="primary" variant="dot" invisible={!(notifications.length > 0)} overlap="circular">
								<NotificationsIcon fontSize="large" onClick={toggleDrawer(true)} className="notifications-icon">
									Dashboard
								</NotificationsIcon>
							</Badge>
						</ThemeProvider>
						<SwipeableDrawer
							anchor={"right"}
							open={notificationOpen}
							onClose={toggleDrawer(false)}
							onOpen={toggleDrawer(true)}
							className="notification-mobile-swipe"
						>
							<div className="close-swipeabledrawer">
								<FontAwesomeIcon
									icon={faClose as IconProp}
									cursor="pointer"
									className="close-swipeabledrawer-icon fa-2xl"
									title="Delete all"
									onClick={toggleDrawer(false)}
								/>
							</div>
							<Notifications />
							<Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}></Box>
						</SwipeableDrawer>
					</React.Fragment>
					<div className="notification-half-filler transparent-background"></div>
				</div>
			</div>

			<div className="navbar-footer-mobile">
				<Nav.Link onClick={() => changePage("home")} className={"nav-link-home page-selected"} disabled={props.isTutorial}>
					<img src={`${myStorageUrl}vectors/dashico.svg`} id="dashboard-icon" className="tab-image" alt="logo" />
				</Nav.Link>
				<Nav.Link onClick={() => changePage("gamehub")} className="nav-link-gamehub" style={{ opacity: props.isTutorial ? "1" : "0.5" }}>
					<PulseDiv pulse={props.isTutorial} color="white" style={{ paddingLeft: "0.3rem" }}>
						<img src={`${myStorageUrl}vectors/gameico.svg`} id="gamehub-icon" className="tab-image" alt="logo" />
					</PulseDiv>
				</Nav.Link>

				<Nav.Link
					onClick={() => {
						changePage("market");
					}}
					className="nav-link-market"
					disabled={isMarketDisabled()}
				>
					<img src={`${myStorageUrl}vectors/markico.svg`} id="market-icon" className="tab-image" alt="logo" />
				</Nav.Link>
				<Nav.Link onClick={() => changePage("profile")} className="nav-link-settings">
					<PersonIcon sx={{
						fontSize: '3em'
					}} />
				</Nav.Link>
				{props.role === "ADMIN" && (
					<Nav.Link onClick={() => changePage("statsDashboard")} className={"nav-link-statsDashboard"} disabled={props.isTutorial}>
						<img src={`${myStorageUrl}images/serverStats.png`} id="stats-icon-navbar" className="tab-image" alt="stats" />
					</Nav.Link>
				)}
			</div>
		</>
	);
};
