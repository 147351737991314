import { Box as CustomComponent, Card, Grid, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as EStormLogo } from "../../../../assets/images/eStorm_logo.svg";
import galaxyBoxBackground from "../../../../assets/galaxy_box_background.jpg";
import { useContext, useMemo, useState } from "react";
import { Inventory } from "../../../../types/Payments";
import { ConfirmBuyDialog } from "./ConfirmBuyDialog";
import { useTranslation } from "react-i18next";
import type { Swiper as SwiperType } from "swiper";
import { SwiperBox } from "./SwiperBox";
import { AuthContext } from "../../../Providers/AuthProvider";
import { UnauthorizedDialog } from "../../UnauthorizedDialog";

const StyledCard = styled(Card)`
	width: 70%;
	height: 100%;
	position: relative;
`;

const StyledLogo = styled(CustomComponent)`
	width: 50px;
	height: 50px;
	position: absolute;
	left: 10px;
	bottom: 10px;
`;

const StyledButton = styled.button`
	width: 50%;
	height: 50px;
	text-align: center;
`;

const ButtonContainer = styled(Grid)`
	button {
		border-radius: 12px;
	}
`;

const GemButton = styled.button`
	background: ${(props) => props.background};
	${(props) =>
		props.item === "galaxyBox"
			? `text-shadow: -1px -1px 10px #000, 1px -1px 10px #000, -1px 1px 10px #000, 1px 1px 10px #000`
			: `text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000`}
`;

const ACTIVE_BUTTONS_STYLE = {
	silverBox: "linear-gradient(180deg, #DEDEDE, transparent)",
	voidBox: "linear-gradient(180deg, #8B18CCB2, transparent)",
	goldBox: "linear-gradient(180deg, #FFD600, transparent)",
	galaxyBox: `url(${galaxyBoxBackground}) 0/cover`,
};

export const GemsPack = ({ gemsPack }: { gemsPack: Inventory.GemsPack }) => {
	const { profile } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");
	const isMobile = useMediaQuery("(max-width: 1199px)");

	const [selected, setSelected] = useState<Inventory.ItemsName>("silverBox");
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isUnauthorizedDialogOpen, setIsUnauthorizedDialogOpen] = useState<boolean>(false);
	const [swiper, setSwiper] = useState({} as SwiperType);

	const Buttons = useMemo(
		() =>
			Object.entries(gemsPack)
				.sort((a, b) => a[1].boltsGiven - b[1].boltsGiven)
				.map(([key, item], i) => (
					<Grid key={`button-${key}`} item xs={3}>
						<GemButton
							type="button"
							className="btn-gems-pack"
							item={key}
							background={selected === key ? ACTIVE_BUTTONS_STYLE[key] : undefined}
							onClick={() => {
								setSelected(key as Inventory.ItemsName);
								swiper.slideTo(i);
							}}>
							{item.boltsGiven}
						</GemButton>
					</Grid>
				)),
		[gemsPack, selected, swiper]
	);

	const handleBuy = () => {
		if (profile === null) setIsUnauthorizedDialogOpen(true);
		else setIsDialogOpen(true);
	};

	return (
		<>
			<UnauthorizedDialog open={isUnauthorizedDialogOpen} handleClose={() => setIsUnauthorizedDialogOpen(false)} />
			<ConfirmBuyDialog open={isDialogOpen} handleClose={() => setIsDialogOpen(false)} item={gemsPack[selected]} />
			<StyledCard>
				<StyledLogo component={EStormLogo} />
				<Grid container height="100%" justifyContent="center" flexDirection="row" alignItems="center" p={{ xs: 1, lg: 0 }} pr={{ lg: 2 }}>
					{/* DESKTOP SWIPER */}
					{!isMobile ? (
						<Grid item lg={6}>
							<SwiperBox setSelected={setSelected} swiper={swiper} setSwiper={setSwiper} />
						</Grid>
					) : null}
					<Grid item xs={12} lg={6}>
						<Grid container flexDirection="column" justifyContent="space-around" gap={3} px={{ xs: 1, lg: 0 }} py={{ lg: 1 }}>
							<Grid item lg={12}>
								<h2 style={{ fontWeight: "bold", textTransform: "capitalize" }}>{gemsPack[selected].name}</h2>
								<p>{t("purchase bolts to access eStorm services")}</p>
							</Grid>
							{/* MOBILE SWIPER */}
							{isMobile ? (
								<Grid item xs={12}>
									<SwiperBox setSelected={setSelected} swiper={swiper} setSwiper={setSwiper} />
								</Grid>
							) : null}
							<Grid item lg={12} display="flex" flexDirection="column" gap={{ xs: 2 }}>
								<ButtonContainer container columns={13} justifyContent="space-between" flexDirection="row" alignItems="center" mb={2}>
									{Buttons}
								</ButtonContainer>
								<CustomComponent component="h4" textAlign={{ xs: "center", lg: "left" }}>
									{t("price")} {(gemsPack[selected].price / 100).toFixed(2)} €{" "}
									<CustomComponent component="span" fontSize={14} color={"grey"}>
										{t("market.box.vat")}
									</CustomComponent>
								</CustomComponent>
								<Grid container justifyContent={{ xs: "center", md: "flex-end" }}>
									<StyledButton onClick={handleBuy} type="button" className={`btn-no-fill text-p`}>
										{t("buy")}
									</StyledButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</StyledCard>
		</>
	);
};
