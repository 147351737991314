import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { UserDoc } from "../../../types/User";
import { Payments } from "../../../types/Payments";

type Analytics = {
	uid: string;
	influencerUsername: string;
	influencerEmail: string;
	userEmail: string;
	gemsPackPrice: number;
	date: number;
};

export const AnalyticsInfluencer = (): JSX.Element => {
	const { myFS } = useContext(FirebaseContext);

	const [show, setShow] = useState(false);

	const [analyticsDetails, setAnalyticsDetails] = useState([] as Analytics[]);
	const [viewAnalyticsDetails, setViewAnalyticsDetails] = useState([] as Analytics[]);

	const getUsersSignedInWithPromoCode = async () => {
		const usersRef = collection(myFS, "users");

		const usersSnapWithPromoCode = await getDocs(query(usersRef, where("promoCodeUsed", "!=", "")));
		const usersWithPromoCode = [] as UserDoc[];
		for (const userSnap of usersSnapWithPromoCode.docs) {
			const userData = userSnap.data() as UserDoc;
			usersWithPromoCode.push(userData);
		}
		console.log("usersWithPromoCode", usersWithPromoCode);

		return usersWithPromoCode;
	};

	const getInfluencers = async () => {
		const usersRef = collection(myFS, "users");
		const usersSnapInfluencer = await getDocs(query(usersRef, where("promoCode", "!=", "")));
		const influencers = [] as UserDoc[];
		for (const userSnap of usersSnapInfluencer.docs) {
			const userData = userSnap.data() as UserDoc;
			influencers.push(userData);
		}
		console.log("influencers", influencers);
		return influencers;
	};

	const getPaymentsCompleted = async () => {
		const paymentsRef = collection(myFS, "payments/paypal/receipts");
		const paymentsSnapCompleted = await getDocs(query(paymentsRef, where("status", "==", Payments.PayPal.Status.COMPLETED)));
		const paymentsCompleted = [] as Payments.PayPal.Receipts[];
		for (const paymentSnap of paymentsSnapCompleted.docs) {
			const paymentData = paymentSnap.data() as Payments.PayPal.Receipts;
			paymentsCompleted.push(paymentData);
		}
		console.log("paymentsCompleted", paymentsCompleted);
		return paymentsCompleted;
	};

	const handleFetch = async () => {
		const usersWithPromoCode = await getUsersSignedInWithPromoCode();
		const influencers = await getInfluencers();
		const paymentsCompleted = await getPaymentsCompleted();

		const analyticsDetails = [] as Analytics[];

		for (const influencer of influencers) {
			const users = usersWithPromoCode.filter((user) => user.promoCodeUsed === influencer.promoCode.code);

			const payments = paymentsCompleted.map((payment) => {
				const user = users.find((user) => user.email === payment.user.email);
				if (!user) return null;

				return { ...payment, user };
			});

			const filteredPayments = payments.filter((payment) => payment !== null);

			for (const payment of filteredPayments) {
				analyticsDetails.push({
					uid: influencer.uid,
					influencerUsername: influencer.username,
					influencerEmail: influencer.email,
					userEmail: payment.user.email,
					gemsPackPrice: payment.financialReceipt.itemPrice,
					date: payment.financialReceipt.date.seconds,
				});
			}
		}

		analyticsDetails.sort((d1, d2) => new Date(d2.date).getTime() - new Date(d1.date).getTime());

		setAnalyticsDetails(analyticsDetails);
		setViewAnalyticsDetails(analyticsDetails);
	};

	const handleRemove = (date: number) => {
		setViewAnalyticsDetails(viewAnalyticsDetails.filter((detail) => detail.date !== date));
	};

	const handleSearch = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;

			if (value === "") return setViewAnalyticsDetails(analyticsDetails);

			const filtered = analyticsDetails.filter(
				(detail) =>
					detail.influencerUsername.includes(value) ||
					detail.influencerEmail.includes(value) ||
					detail.userEmail.includes(value) ||
					`${detail.gemsPackPrice / 100} €`.includes(value) ||
					new Date(detail.date * 1000).toLocaleString().includes(value)
			);

			setViewAnalyticsDetails(filtered);
		},
		[analyticsDetails]
	);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<>
			<button type="button" className="btn-no-fill" style={{ width: "20%" }} onClick={() => setShow(!show)}>
				<h3>{show ? "Hide" : "Show"} Analytics Influencer</h3>
			</button>
			<div style={{ display: show ? "block" : "none" }}>
				<h1>Analytics Influencer</h1>
				<input type="search" placeholder="Search" onChange={handleSearch} />
				<div style={{ height: "500px", overflowY: "scroll" }}>
					<table>
						<thead>
							<tr>
								<th>Influencer Username</th>
								<th>Influencer Email</th>
								<th>User Email</th>
								<th>Taglio acquistato</th>
								<th>Data di aquisto</th>
							</tr>
						</thead>
						<tbody>
							{viewAnalyticsDetails.map((detail) => {
								return (
									<tr key={detail.date}>
										<td>{detail.influencerUsername}</td>
										<td>{detail.influencerEmail}</td>
										<td>{detail.userEmail}</td>
										<td>{detail.gemsPackPrice / 100} €</td>
										<td>{new Date(detail.date * 1000).toLocaleString()}</td>
										<td>
											<button onClick={async () => await handleRemove(detail.date)}> X </button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};
