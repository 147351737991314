import { useEffect, useContext, useState } from "react";

import { Button } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { styled } from "@mui/material/styles";
import { DisclamerHoverProps } from "../../types/Props";
import { Placements } from "../../types/Styles";

import "../../styles/DisclamerHover.scss";

export const DisclamerHover = (props: DisclamerHoverProps): JSX.Element => {
	const { disclamerOpen, setDisclamerOpen, hoverStyle, placement, htmlHover } = props;
	const [minWidth, setMinWidth] = useState(100);
	const [background, setBackground] = useState("rgba(0,0,0,1)");
	const [color, setColor] = useState("rgba(255, 255, 255, 0.87)");

	const { myStorageUrl } = useContext(FirebaseContext);
	const handleTooltip = (): void => {
		setDisclamerOpen(!disclamerOpen);
	};
	const handleMouseLeave = (): void => {
		setDisclamerOpen(false);
	};
	const HtmlTooltip = styled(({ className, ...props }: TooltipProps): JSX.Element => <Tooltip {...props} classes={{ popper: className }} />)(
		({ theme }) => ({
			[`& .${tooltipClasses.tooltip}`]: {
				background: background,
				color: color,
				maxWidth: 220,
				minWidth: minWidth,
				fontSize: theme.typography.pxToRem(12),
				borderRadius: "16px",
				marginTop: "0 !important",
			},
		})
	);
	useEffect(() => {
		if (hoverStyle != null) {
			if (hoverStyle.minWidth != null) setMinWidth(hoverStyle.minWidth);
			if (hoverStyle.background != null) setBackground(hoverStyle.background);
			if (hoverStyle.color != null) setColor(hoverStyle.color);
		}
	}, [disclamerOpen]);

	useEffect(() => {
		document.querySelector("body").addEventListener("scroll", handleMouseLeave, { passive: true });
		return () => {
			document.querySelector("body").removeEventListener("scroll", handleMouseLeave);
		};
	});

	return (
		<>
			<HtmlTooltip
				title={<div>{htmlHover}</div>}
				onClose={handleTooltip}
				onMouseEnter={handleTooltip}
				onMouseLeave={handleMouseLeave}
				onScroll={handleMouseLeave}
				onScrollCapture={handleMouseLeave}
				open={disclamerOpen}
				placement={placement as Placements}
				sx={{ background: background }}
			>
				<Button onClick={handleTooltip} sx={{ padding: 0 }} className="hover-information-button">
					<img src={`${myStorageUrl}images/icon-information.png`}></img>
				</Button>
			</HtmlTooltip>
		</>
	);
};
