import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { MarketContext } from "../../../Providers/MarketProvider";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Tooltip, useMediaQuery } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import "../../../../styles/dashboard/market/giftcard.scss";
import { CustomSlider } from "../../../Slider/CustomSlider";
import { OutlinedButtonV1 } from "../../../Buttons/CustomOutlinedButtonV1";
import { PaypalButton } from "../../Payments/PaypalButton";
import { countries, ICountry } from "countries-list";
import { CustomSelect } from "../../../Select/CustomSelect";
import { InputText } from "../../../Input/InputText";
import { NetworkContext } from "../../../Providers/NetworkProvider";

type DialogConfirmBuyGenericCardProps = {
	open: boolean;
	handleClose: () => void;
	card: {
		category: string;
		title: React.ReactNode;
		serviceName: string;
		itemName: string;
		reward: string;
		price: number;
		priceInBolts: number;
		uid: number;
	};
};

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FISCAL_CODE_REGEX = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;

const countryCodes = Object.keys(countries);
const countryNames = countryCodes.map((code) => (countries[code] as ICountry).name);

const calculateDiscount = (sliderValue: number, itemPriceInBolts: number): number => {
	const discountAppliedToEveryStepOfSlider = 5;
	const fivePercentOfPrice = itemPriceInBolts * 0.05;
	const discountInBolts = fivePercentOfPrice * (sliderValue / discountAppliedToEveryStepOfSlider);
	return discountInBolts;
};

const STEP_SLIDER = 5;
const MIN_VALUE_SLIDER = {
	value: 50,
	label: "50% (min)",
};
const MAX_VALUE_SLIDER = {
	value: 90,
	label: "90% (max)",
};
/**
 * Calculate the number of steps for the slider
 * @example
 * // The slider goes from 50 to 90 with a step of 5
 * // So the number of steps are:
 * NUMBER_OF_STEPS = (90 - 50 - 5) / 5; // == 7
 * // --> 55, 60, 65, 70, 75, 80, 85
 */
const NUMBER_OF_STEPS = (MAX_VALUE_SLIDER.value - MIN_VALUE_SLIDER.value - STEP_SLIDER) / STEP_SLIDER;

/**
 * Create an array of objects `{value: number}` from `MIN_VALUE_SLIDER` to `MAX_VALUE_SLIDER` with a step of `STEP_SLIDER`
 *
 * This is used to create the marks for the slider
 *
 * Since the slider has 0 as the first value and then he goes from 50 to 90 with a step of 5, we need to create a marks array that goes from 50 to 90 with a step of 5
 */
const STEP_VALUE_SLIDER = Array.from({ length: NUMBER_OF_STEPS }, (_, i) => i + 1).map((value) => ({
	value: value * STEP_SLIDER + MIN_VALUE_SLIDER.value,
}));

export const DialogConfirmBuyGenericCard = ({ open, handleClose, card }: DialogConfirmBuyGenericCardProps) => {
	const { profile } = useContext(AuthContext);
	const { oneBoltInCents, canUserApplyDiscount, setCanUserApplyDiscount } = useContext(MarketContext);
	const { t: tDashboard } = useTranslation("dashboard");
	const isMobile = useMediaQuery("(max-width: 900px)");

	const [showSlider, setShowSlider] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [price, setPrice] = useState(card.price);
	const [discountInBolts, setDiscountInBolts] = useState(0);
	const [countrySelected, setCountrySelected] = useState<string>("");
	const [fiscalCode, setFiscalCode] = useState<string>("");

	const isFiscalCodeValid = useMemo(() => {
		return FISCAL_CODE_REGEX.test(fiscalCode);
	}, [fiscalCode]);

	const showPaymentButtons = useMemo(() => {
		if (countrySelected.length === 0) return false;
		if (countrySelected === "Italy" && profile?.fiscalCode != undefined) return true;
		return countrySelected === "Italy" ? isFiscalCodeValid : true;
	}, [countrySelected, isFiscalCodeValid, profile]);

	const onChangeSlider = (_: Event, value: number | number[]) => {
		const discount = calculateDiscount(value as number, card.priceInBolts);
		setDiscountInBolts(discount);
		const price = Math.round(card.price * (1 - (value as number / 100)));
		setPrice(price);
	};

	const handleCloseDialog = useCallback(() => {
		setShowSlider(false);
		setCountrySelected("");
		setFiscalCode("");
		setDiscountInBolts(0);
		setPrice(card.price);
		handleClose();
	}, [card.price, handleClose]);

	const calculateVat = (price: number) => {
		return price * 0.22;
	}

	if (profile == null) return null;

	return (
		<Dialog
			open={open}
			onClose={handleCloseDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			TransitionComponent={Transition}>
			<DialogTitle id="alert-dialog-title" fontSize={25} fontWeight="bold" sx={{ paddingTop: 0 }}>
				{tDashboard("purchasing")} {card.title}
			</DialogTitle>
			<DialogContent sx={{ minHeight: "125px" }}>
				<span>Item: {card.reward} </span>
				<br />
				<span>Price: € {(price / 100).toFixed(2)}</span>
				{
					(card.price - price > 0) && (
						<span style={{ color: "#27ae60" }}>
							(- {((card.price + calculateVat(price) - price) / 100).toFixed(2)} €)
						</span>
					)
				}
				<span> + VAT</span>
				<br />
				<span style={{ textTransform: "capitalize" }}>{tDashboard("new balance")}</span> {profile.bolts.toFixed(6)} {tDashboard("bolts")}
				{discountInBolts > 0 && <span style={{ color: "red" }}> (- {discountInBolts})</span>}
				<hr />
				{tDashboard("are you sure to proceed with the purchase")}
			</DialogContent>

			<DialogActions sx={{ justifyContent: "center", gap: 3, overflowY: "scroll" }}>
				{isLoading ? (
					<CircularProgress
						size={24}
						sx={{
							color: "#dedede",
							top: "50%",
							left: "50%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
				) : (
					<>
						<Grid container spacing={4} justifyContent="center">
							{countrySelected.length === 0 && (
								<Grid item xs={12}>
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
										<span className="text-p" style={{ float: "left" }}>
											{tDashboard("country.of.residence")}:
										</span>
										<CustomSelect
											onChange={(newValue) => {
												setCountrySelected(newValue);
											}}
											values={countryNames}
										/>
									</Box>
								</Grid>
							)}
							{profile.fiscalCode !== undefined ||
								(countrySelected === "Italy" && !profile.fiscalCode && (
									<Grid item xs={12}>
										<Box>
											<span className="text-p">{tDashboard("provide.fiscal.code.invoice")}:</span>
											<br />
											<Box paddingTop="15px" paddingBottom="15px">
												<Box textAlign="center">
													<InputText
														type="text"
														id="txtFC"
														idErr="errFC"
														placeholder="Fiscal code"
														fun={(e) => setFiscalCode(e.target.value.toUpperCase())}
													/>
												</Box>
												{!isFiscalCodeValid && <label style={{ color: "red" }}>{"Codice fiscale incompleto o errato"}</label>}
											</Box>
										</Box>
									</Grid>
								))}
							{showPaymentButtons && (
								<Grid
									item
									position="relative"
									xs={12}
									md={8}
									mt={showSlider ? 3 : 0}
									display="flex"
									justifyContent="center"
									flexDirection="column"
									gap={3}>
									{showSlider ? (
										<CustomSlider
											disabled={false}
											ariaLabel="Bolts"
											defaultValue={50}
											onChange={onChangeSlider}
											marks={[
												{
													value: 0,
													label: "0%",
												},
												{ ...MIN_VALUE_SLIDER },
												...STEP_VALUE_SLIDER,
												{ ...MAX_VALUE_SLIDER },
											]}
										/>
									) : (
										<Tooltip
											title={!canUserApplyDiscount && <span className="text-cta">{tDashboard("market.generic.card.dialog.disclaimer")}</span>}
											placement="top"
											arrow>
											<div style={{ cursor: "help" }}>
												<OutlinedButtonV1
													content={tDashboard("market.generic.card.dialog.cta")}
													onClick={() => {
														setShowSlider(true);
														onChangeSlider(null, 50);
													}}
													disabled={!canUserApplyDiscount}
													sx={{
														width: "100%",
														height: "45px",
														filter: `${!canUserApplyDiscount ? "blur(1px)" : "blur(0px)"}`
													}}
												/>
											</div>
										</Tooltip>
									)}
									<PaypalButton
										cart={{
											uid: card.uid,
											name: card.itemName,
											category: card.category,
											boltsGiven: price,
										}}
										boltsUsedForDiscount={discountInBolts}
										onSuccess={() => {
											setCanUserApplyDiscount(true);
											handleClose();
										}}
										fiscalCode={profile.fiscalCode == undefined ? fiscalCode : profile.fiscalCode}
									/>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Grid width={1} display="flex" alignItems="center" flexDirection={isMobile ? "column" : "row"} gap={isMobile ? 3 : 2}>
									<Box
										component="button"
										border="1px solid transparent"
										padding="8px 20px"
										margin="3px"
										fontSize="16px"
										borderRadius="50px"
										color="#FFFFFF"
										minWidth="166px"
										height="45px"
										width={1}
										sx={{
											background: "transparent",
											transition: "background 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out",
											"&:hover": {
												border: "1px solid #FFFFFF",
											},
										}}
										onClick={handleCloseDialog}>
										{tDashboard("cancel")}
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};