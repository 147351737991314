import { useContext, useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DashboardMarketProps } from "../../types/Props";
import "../../styles/DropDown.scss";
import "../../styles/DashboardMarket.scss";
import "react-widgets/styles.css";
import { FooterLanding } from "../home/landing/FooterLanding";
import { Inventory } from "../../types/Payments";
import { GemsPack } from "./Market/GemsPacks/GemsPack";
import { MarketContext } from "../Providers/MarketProvider";
import { Subscription } from "./Market/Subscription/Subscription";
import { GenericCard } from "./Market/GiftCards/GenericCard";

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

export const DashboardMarket = ({ name }: { name?: string }): JSX.Element => {
	const { getItemsFromDB } = useContext(MarketContext);
	const [items, setItems] = useState<Inventory.Items>(undefined);

	useEffect(() => {
		(async () => {
			const items = await getItemsFromDB();
			setItems(items);
		})();
	}, []);

	return (
		<>
			<div className="market-main">
				<ThemeProvider theme={theme}>
					<Grid container justifyContent="center" alignItems="center" flexDirection="column" gap={5} sx={{ marginTop: "3em" }}>
						{items === undefined ? (
							<Skeleton variant="rectangular" width="50%" height="300px" sx={{ bgcolor: "grey.700", borderRadius: "30px" }} animation={"pulse"} />
						) : (
							<>
								<GemsPack gemsPack={items.gemsPack} />
								<Subscription subscriptionInfo={items.subscription} />
								{items.rewards &&
									items.rewards.map((rewardItem, index) => (
										<GenericCard
											key={`${rewardItem.service.toLowerCase()}-${rewardItem.reward.toLowerCase()}`}
											rewardItem={rewardItem}
										/>
									))}
							</>
						)}
					</Grid>
				</ThemeProvider>
				<div style={{ position: "relative", marginBottom: "2em" }}>
					<FooterLanding />
				</div>
			</div>
		</>
	);
};
