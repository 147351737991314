import { GoogleAuthProvider, getRedirectResult, signInWithPopup, setPersistence, UserCredential, browserSessionPersistence } from "firebase/auth";

import "../../styles/Images.scss";
import $ from "jquery";
import { useContext } from "react";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { AuthContext } from "../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { ThirdPartyAuthSignInProps } from "../../types/Props";
import { useSnackbar } from "../Providers/SnackbarProvider";

export let ThirdPartyAuthSignIn = (props: ThirdPartyAuthSignInProps): JSX.Element => {
	const { myAuth } = useContext(FirebaseContext);
	const { validateCode, createUserAfterGoogleSignIn, loading } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");
	const { openSnackbar } = useSnackbar();

	if (myAuth) {
		getRedirectResult(myAuth)
			.then(async (userCredentials): Promise<void> => {
				if (!userCredentials.user) return;
				await handleSignInWihtAuthProvider(
					userCredentials.user.email,
					userCredentials.user.displayName,
					undefined,
					undefined,
					userCredentials.user.uid
				);
			})
			.catch((err): void => {
				if (err.message !== "Cannot read properties of null (reading 'user')") {
					console.error(err.message);
				}
			});
	}

	let signWithAuthProvider = async (): Promise<void> => {
		if (loading) return;

		let codice = $("#txtCodice").val() as string;
		let UsingCode = false;

		if (codice !== "" && codice !== undefined) {
			UsingCode = true;
		}

		try {
			if (UsingCode) {
				let { isCodeValid, message } = await validateCode(codice);
				if (!isCodeValid) {
					openSnackbar("Error", message, "error");
					return;
				}
			}
		} catch (e) {
			console.error(e);
		}

		const provider = new GoogleAuthProvider();
		provider.setCustomParameters({
			prompt: "consent",
		});
		await setPersistence(myAuth, browserSessionPersistence);
		let result: UserCredential | never;

		try {
			if (props.source === "signup" && !props.privacyAccepted) {
				openSnackbar("Warning", t("you must accept our privacy policy"), "warning");
				return;
			}
			result = (await signInWithPopup(myAuth, provider)) as UserCredential;
			await handleSignInWihtAuthProvider(result.user.email, result.user.displayName, codice, UsingCode, result.user.uid);
			window.location.href = "/dashboard";
		} catch (err) {
			console.error(err);
			switch (err.code) {
				case "auth/account-exists-with-different-credential":
					break;
				case "auth/popup-closed-by-user":
					break;
				default:
					openSnackbar("Error", err.code, "error");
					break;
			}
		}
	};

	let handleSignInWihtAuthProvider = async (
		email: string,
		displayName: string,
		promoCode: string,
		usingCode: boolean,
		uid: string
	): Promise<void> => {
		if (!email) {
			console.error("Email not defined");
			return;
		}
		if (!displayName) {
			console.error("Name of user not defined");
			return;
		}
		if (!uid) {
			console.error("uid is undefined");
			return;
		}
		try {
			const userCreated = await createUserAfterGoogleSignIn({
				email: email,
				username: displayName,
				promoCode: promoCode,
				UsingCode: usingCode,
				uid: uid,
			});
			if (userCreated) {
				openSnackbar(
					"Success",
					"Before proceeding with the creation of your storm account, we would like to let you know that our site uses cookies only and exclusively to make the user experience unique.",
					"info"
				);
			}
		} catch (err) {
			console.error(err);
			if (err.response !== undefined) {
				switch (err.response.data.message) {
					case "Email taken":
						openSnackbar("Success", "Welcome back, redirecting you...", "success");
						break;
					default:
						console.error(err);
				}
			}
		}
	};

	return (
		<>
			<button className="gsi-material-button mb-4" onClick={signWithAuthProvider}>
				<div className="gsi-material-button-state"></div>
				<div className="gsi-material-button-content-wrapper">
					<div className="gsi-material-button-icon">
						<svg
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 48 48"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							style={{ display: "block" }}>
							<path
								fill="#EA4335"
								d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
							<path
								fill="#4285F4"
								d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
							<path
								fill="#FBBC05"
								d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
							<path
								fill="#34A853"
								d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
							<path fill="none" d="M0 0h48v48H0z"></path>
						</svg>
					</div>
					<span className="gsi-material-button-contents">{t("continue with google")}</span>
				</div>
			</button>
		</>
	);
};
