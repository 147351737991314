import { useContext, useEffect, useState, useRef, KeyboardEvent } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AuthContext } from "./Providers/AuthProvider";
import { FirebaseContext } from "./Providers/FirebaseProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import Badge from "@mui/material/Badge";
import Fade from "@mui/material/Fade";
import { createTheme, SxProps, ThemeProvider } from "@mui/material/styles";
import { useSwipeable } from "react-swipeable";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const Notifications = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const { profile, notifications, setNotifications, lastNotification } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");
	const { myFS } = useContext(FirebaseContext);
	const prevOpen = useRef(open);

	const [notificationBox, setNotificationBox] = useState([]);
	const [notificationToRead, setNotificationToRead] = useState(false);
	const [lastNotificationShow, setlastNotificationShow] = useState(<></>);

	const handleToggle = (): void => {
		if (prevOpen.current) {
			$(".notification-half-filler").addClass("transparent-background");
			$(".notification-header").addClass("transparent-background");
			$(".notification-container").addClass("transparent-background");
		} else {
			$(".notification-header").removeClass("transparent-background");
			$(".notification-half-filler").removeClass("transparent-background");
			$(".notification-container").removeClass("transparent-background");
		}
		setOpen((prevOpen): boolean => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent): void => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		$(".notification-half-filler").addClass("transparent-background");
		$(".notification-header").addClass("transparent-background");
		$(".notification-container").addClass("transparent-background");
		setOpen(false);
	};

	function handleListKeyDown(event: KeyboardEvent<HTMLUListElement>): void {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}
	const deleteNotification = async (id: string): Promise<void> => {
		const docRef = doc(myFS, `users/${profile.uid}/notifications/${id}`);
		await deleteDoc(docRef);

		if (notifications.length === 1) {
			setNotifications([]);
			setlastNotificationShow(<></>);
		}
	};

	const deleteNotificationBox = async (notificationId: string): Promise<void> => {
		$(`#${notificationId}`).fadeTo(500, 0, () => {
			$(`#${notificationId}`).css("display", "none");
		});
		await deleteNotification(notificationId);
	};

	const deleteAllNotificationBox = async (): Promise<void> => {
		if (notifications.length === 0) {
			return;
		} else {
			for (let i = 0; i < notifications.length; ++i) {
				await deleteNotification(notifications[i].id);
				$(`#${notifications[i].id}`)
					.delay(i * 500)
					.fadeTo(500, 0, () => {
						//When fadeTo is complete -> set css display: none
						$(`#${notifications[i].id}`).css("display", "none");
					});
			}
			setNotifications([]);
		}
	};
	useEffect(() => {
		if (profile != null) {
			setNotificationBox([]);
			try {
				if (notifications.length > 0) {
					let stringD, stringH, stringM;

					//Load notification panel
					for (let i = 0; i < notifications.length; ++i) {
						let n;

						stringD = notifications[i].time.date !== 0 ? `${notifications[i].time.date}d ` : "";
						stringH = notifications[i].time.hours !== 0 ? `${notifications[i].time.hours}h ` : "";
						stringM = notifications[i].time.minutes !== 0 ? `${notifications[i].time.minutes}m ` : "";

						n = (
							<Grid
								container
								direction={"row"}
								spacing={1}
								justifyContent={"space-between"}
								alignItems={"center"}
								className="notification-box-content"
								id={notifications[i].id}
								key={notifications[i].id}>
								<Grid item md={2} alignSelf={"center"}>
									<img src={process.env.PUBLIC_URL + "/eStorm_logo.svg"} className="notification2" alt="notification2" />
								</Grid>
								<Grid item xs md className="notification-content-text">
									<Grid container direction={"column"}>
										<Grid item>
											<span className="text-p-bold">{notifications[i].message}</span>
										</Grid>
										<Grid item>
											<span className="text-label">
												{stringD === "" && stringH === "" && stringM === "" ? "now" : `${stringD + stringH + stringM} ago`}
											</span>
										</Grid>
									</Grid>
								</Grid>
								<Box
									component={Grid}
									item
									md={1}
									alignSelf={"baseline"}
									display={{ xs: "none", md: "block", lg: "block" }}
									className="close-notification">
									<FontAwesomeIcon
										icon={faClose as IconProp}
										className="crossNotifications fa-2xl"
										onClick={() => deleteNotificationBox(notifications[i].id)}
									/>
								</Box>
							</Grid>
						);
						setNotificationBox((prevBox) => [...prevBox, n]);
					}
					setNotificationToRead(true);
					$(".notifications-icon").addClass("purple-color");
					$(".notification-alert").fadeTo(200, 1, () => {
						$(".notification-alert").css("display", "block");
					});
				} else {
					let n = (
						<div className="notification-box-content" id="0">
							<label> {t("no notifications to read")}</label>
						</div>
					);
					setNotificationToRead(false);
					setlastNotificationShow(<></>);
					$(".notifications-icon").removeClass("purple-color");

					setNotificationBox([n]);
				}
			} catch (err) {
				if (err.message === "Network Error") window.location.href = "/networkError";
				if (err.message === "Request failed with status code 401") console.log(err.message);
				if (err.message === "Request aborted") window.location.href = "/";
			}
		}
	}, [profile, notifications]);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const handlers = useSwipeable({
		onSwiped: (eventData) => {},
		trackMouse: true,
		delta: 10,
		swipeDuration: Infinity,
	});

	useEffect(() => {
		if (profile != null) {
			if (lastNotification) {
				$("#notification-pop").css({ left: "unset", right: "-1em" });
				let stringD = lastNotification.time.date !== 0 ? `${lastNotification.time.date}d ` : "";
				let stringH = lastNotification.time.hours !== 0 ? `${lastNotification.time.hours}h ` : "";
				let stringM = lastNotification.time.minutes !== 0 ? `${lastNotification.time.minutes}m ` : "";
				setlastNotificationShow(
					<div className="notification-popper" {...handlers} id="notification-pop">
						<Grid
							{...handlers}
							container
							direction={"row"}
							spacing={1}
							justifyContent={"space-between"}
							alignItems={"center"}
							className="notification-box-content"
							sx={{ margin: 0 }}>
							<Grid item md={1} alignSelf={"center"} className="notification-popper-icon">
								<img src={process.env.PUBLIC_URL + "/eStorm_logo.svg"} className="notification2" alt="notification2" />
							</Grid>
							<Grid item xs md className="notification-content-text">
								<Grid container direction={"column"}>
									<Grid item>
										<span className="text-p-bold" style={{ whiteSpace: "nowrap" }}>
											{lastNotification.message}
										</span>
									</Grid>
									<Grid item>
										<span className="text-label">
											{stringD === "" && stringH === "" && stringM === "" ? "now" : `${stringD + stringH + stringM} ago`}
										</span>
									</Grid>
								</Grid>
							</Grid>
							<Box
								component={Grid}
								item
								md={1}
								alignSelf={"baseline"}
								display={{ xs: "none", md: "block", lg: "block" }}
								className="cross-notification-pop-container">
								<FontAwesomeIcon icon={faClose as IconProp} className="cross-notifications-pop fa-xl" onClick={closeNotification} />
							</Box>
						</Grid>
					</div>
				);
			}
		}
	}, [profile, lastNotification]);

	const closeNotification = async () => {
		let away = $(document).width();
		$("#notification-pop").animate({ left: away }, 1000);

		if (profile != null) {
			const docRef = doc(myFS, `users/${profile.uid}/notifications/${lastNotification.id}`);
			await updateDoc(docRef, {
				read: true,
			});
		}
	};
	const theme = createTheme({
		palette: {
			primary: {
				main: "#ffffff",
				contrastText: "#fff",
			},
		},
	});

	return (
		<>
			{lastNotificationShow}
			<Stack direction="row" spacing={2} className="notification-desktop">
				<div className="notification-container transparent-background">
					<ThemeProvider theme={theme}>
						<Badge color="primary" variant="dot" invisible={!notificationToRead} overlap="circular">
							<NotificationsIcon
								fontSize="large"
								ref={anchorRef}
								id="notifications-icon"
								aria-controls={open ? "composition-menu" : undefined}
								aria-expanded={open ? "true" : undefined}
								aria-haspopup="true"
								onClick={handleToggle}
								className="notifications-icon"></NotificationsIcon>
						</Badge>
					</ThemeProvider>
					<Popper
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						placement="bottom-end"
						transition
						disablePortal
						className="notification-dropdown">
						{({ TransitionProps, placement }): JSX.Element => (
							<Fade
								{...TransitionProps}
								style={{
									transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
								}}
								timeout={0}>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="notifications-icon" onKeyDown={handleListKeyDown}>
											<Grid>
												<div className="box">
													<Grid container>
														<Grid item xs={10}>
															<h1 className="notification-title">{t("notifications")}</h1>
														</Grid>
														<Grid item xs={2} sx={{ display: "flex", margin: "auto" }}>
															<a className="text-label-underlined-opicity delete-all-text" onClick={deleteAllNotificationBox} style={{ margin: "0" }}>
																Delete all
															</a>
														</Grid>
													</Grid>
													<hr className="border-line" />

													{notificationBox.map((box, index) => (
														<div key={index}>{box}</div>
													))}
												</div>
											</Grid>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Fade>
						)}
					</Popper>
				</div>
			</Stack>

			<Grid className="notifications-mobile">
				<div className="box">
					<Grid container>
						<Grid item xs={11}>
							<h1 className="notification-title">{t("notifications")}</h1>
						</Grid>
						<Grid item xs={1}>
							<p className="text-label-underlined-opicity delete-all-text" onClick={deleteAllNotificationBox}>
								Delete all
							</p>
						</Grid>
					</Grid>
					<hr className="border-line" />

					{notificationBox.map((box, index) => (
						<div key={index}>{box}</div>
					))}
				</div>
			</Grid>
		</>
	);
};
