import { useContext, useEffect, useState } from "react";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import { setPersistence, browserSessionPersistence, sendPasswordResetEmail } from "firebase/auth";
import { InputText } from "../Input/InputText";
import { InputDropDown } from "../Input/InputDropDown";
import { isEmail, isEmpty } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import config from "../../config-file.json";
import { ThirdPartyAuthSignIn } from "./ThirdPartyAuthSignIn";
import { AuthContext } from "../Providers/AuthProvider";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { SettingsContext } from "../Providers/SettingsProvider";
import { useTranslation } from "react-i18next";
import { validInput } from "../../types/Auth";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GenericProps } from "../../types/Props";
import { getTermsAndConditions } from "../../utilities/policies/getPrivacyPoliciesLinks";

export const Login = (props: GenericProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [loadingGoogleLogIn, setLoadingGoogleLogIn] = useState(false);
	const handleButtonClick = (status: boolean): void => {
		setLoading(status);
	};

	const { login } = useContext(AuthContext);
	const { myAuth, myStorageUrl, storagePicturesUrl } = useContext(FirebaseContext);
	const { language, changeLanguage } = useContext(SettingsContext);
	const { t } = useTranslation("dashboard");
	const { t: tGeneral } = useTranslation("translation");

	function changeLanguageOnSelect(event): void {
		changeLanguage(event.target.value);
	}

	const isEmailValid = (email: string): validInput => {
		let message = "";
		let valid = true;

		if (!isEmail(email)) {
			message = t("email not found");

			valid = false;
		}

		if (isEmpty(email)) {
			message = t("email not empty");

			valid = false;
		}

		return { valid, message };
	};

	const isPasswordValid = (password: string): validInput => {
		let message = "";
		let valid = true;

		if (isEmpty(password)) {
			message = t("password not empty");

			valid = false;
		}

		return { valid, message };
	};

	const isDataValid = ({ password, email }): boolean => {
		let isEmail = isEmailValid(email);
		let isPassword = isPasswordValid(password);

		if (!isEmail.valid) {
			$("#errEmail").parent().addClass("error");
			$("#errEmail").text(isEmail.message);
		}

		if (!isPassword.valid) {
			$("#errPassword").parent().addClass("error");
			$("#errPassword").text(isPassword.message);
		}

		return isEmail.valid && isPassword.valid;
	};

	const handleLogin = async (): Promise<void> => {
		// props.showToast('Maintenance ongoing, please retry in 20 minutes', 'Warning');
		// return;
		handleButtonClick(true);
		["#errEmail", "#errPassword"].forEach((id) => {
			$(id).parent().removeClass("error");
			$(id).text("");
		});

		let email = $("#txtEmail").val() as string;
		let password = $("#txtPassword").val() as string;

		if (!isDataValid({ password, email })) {
			handleButtonClick(false);
			return;
		}

		try {
			await setPersistence(myAuth, browserSessionPersistence);
			await login(email, password);
			window.location.href = "/dashboard";
		} catch (error) {
			handleButtonClick(false);
			if (error.code === "auth/user-disabled") {
				props.showToast(
					<>
						{t("account.disabled.1")}
						<a
							href={`${storagePicturesUrl}privacy_policies/${getTermsAndConditions()}`}
							target="_blank"
							rel="noreferrer"
							title="Terms and conditions">
							<b>{t("account.disabled.2")}</b>
						</a>
						{t("account.disabled.3")}
						<a href="mailto:info@storm.co.gg">{t("account.disabled.4")}</a>
					</>,
					"Error"
				);
			}
			if (error.code === "auth/invalid-email") {
				props.showToast(t("email not formatted"), "Error");
			}
			if (error.code === "auth/user-not-found") {
				props.showToast(t("wrong credential"), "Error");
			}
			if (error.code === "auth/wrong-password") {
				props.showToast(t("wrong password"), "Error");
			}
		}
	};

	const openResetPassword = (): void => {
		//SHOW DIALOG ANIMATION
		$(".credentials-box").css("opacity", 0);
		setTimeout(() => {
			$(".credentials-box").css("display", "none");
			$("#resetDialog").css("opacity", 1);
			setTimeout(() => {
				$("#resetDialog").css("display", "block");
			}, 100);
		}, 200);
	};

	const closeResetPassword = (): void => {
		//HIDE DIALOG ANIMATION
		$("#resetDialog").css("opacity", 0);
		setTimeout(() => {
			$("#resetDialog").css("display", "none");
			$(".credentials-box").css("opacity", 1);
			setTimeout(() => {
				$(".credentials-box").css("display", "block");
			}, 100);
		}, 200);
	};

	const resetPassword = async (): Promise<void> => {
		$("#errEmailReset").text("");
		$("#errEmailReset").parent().removeClass("error");

		let email = $("#txtEmailReset").val() as string;

		let { valid, message } = isEmailValid(email);

		if (!valid) {
			$("#errEmailReset").text(message);
			$("#errEmailReset").parent().addClass("error");
			return;
		}

		try {
			await sendPasswordResetEmail(myAuth, email, { url: config[process.env.REACT_APP_ENV]["url-used-to-send-mail-auth-login"] });

			props.showToast(<>{tGeneral("check.email")}</>, "Info");
		} catch (err) {
			console.log(err.message);
			if (err.code === "auth/user-not-found") {
				props.showToast(t("wrong credential"), "Error");
			}
		} finally {
			closeResetPassword();
		}
	};

	useEffect(() => {
		$("body")
			.off()
			.on("keypress", function (e) {
				loginEnter(e);
			});
	});

	function loginEnter(e): void {
		let email = $("#txtEmail").val();
		let password = $("#txtPassword").val();

		if (e.keyCode === 13 && (email !== "" || password !== "")) {
			handleLogin();
		}
	}

	return (
		<>
			<div className="login">
				<video loop={true} autoPlay={true} muted={true} className="login-video" id={"video-container-login"}>
					<source src={`${myStorageUrl}videos/video_background.mp4`}></source>
				</video>

				<div className="login-main">
					<div className="d-flex w-100">
						{/* DIALOG RESET PASSWORD */}
						<div id="resetDialog" className="login-box">
							<FontAwesomeIcon className="fa-xl back-icon" icon={faArrowLeftLong as IconProp} title="Login page" onClick={closeResetPassword} />

							{t("reset password")}
							<hr />
							<InputText type="email" id="txtEmailReset" idErr="errEmailReset" placeholder={t("email placeholder")} />

							<div className="SelectLanguageWrapper d-flex">
								<InputDropDown
									value={language === "en" ? "english" : language === "it" ? "italiano" : "русский"}
									id="selectLanguageHeader"
									options={[
										{
											key: "en",
											value: "en",
											help: "english",
										},
										{
											key: "it",
											value: "it",
											help: "italiano",
										},
									]}
									onChange={changeLanguageOnSelect}
								/>
							</div>

							<button type="button" className="login-btn btn-outline" id="login-button" onClick={resetPassword}>
								{t("submit")}
							</button>
						</div>

						<div className="login-box credentials-box">
							<a href="/">
								<img src={`${myStorageUrl}vectors/STORM_logo.svg`} id="header-logo" alt="storm logo" />
							</a>
							<h1>{t("login")}</h1>
							<hr />
							<InputText type="email" id="txtEmail" idErr="errEmail" placeholder={t("email placeholder")} />

							<InputText type="password" id="txtPassword" idErr="errPassword" placeholder={t("password placeholder")} />

							<label className="text-label reset-password">
								<a className="" onClick={openResetPassword}>
									{t("forgot password")}
								</a>
							</label>

							<div className="buttons-login">
								{loading ? (
									<CircularProgress
										size={24}
										sx={{
											color: grey[50],
											top: "50%",
											left: "50%",
											marginTop: "-12px",
											marginLeft: "-12px",
										}}
									/>
								) : (
									<button type="button" className="login-btn btn-outline" id="login-button" onClick={handleLogin}>
										{t("login")}
									</button>
								)}

								{loadingGoogleLogIn ? (
									<CircularProgress
										size={24}
										sx={{
											color: grey[50],
											top: "50%",
											left: "50%",
											marginBottom: "1em",
										}}
									/>
								) : (
									<>
										<ThirdPartyAuthSignIn
											showToast={props.showToast}
											language={language}
											setLoadingGoogleLogIn={setLoadingGoogleLogIn}
											auth="google"
											source="login"
										/>
									</>
								)}
							</div>

							<label className="text-label" id="signup-label">
								{t("not registered?")} <a href="/signup">{t("sign up")}</a>
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
