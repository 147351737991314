import { Grid, Typography } from "@mui/material";
import "../../../../styles/home/GamehubLanding.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Game } from "./Game";

import { Survey } from "./Survey";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";

export const GameHubLanding = () => {
	const { t } = useTranslation("dashboard");
	const { myStorageUrl } = useContext(FirebaseContext);

	return (
		<>
			<Grid container justifyContent="center">
				<Grid item xs={10}>
					<div style={{ display: "flex", gap: "16px" }}>
						<Grid container>
							<Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
								<Typography
									id="title-gamehub"
									variant="h4"
									fontFamily="sora"
									fontSize="30px"
									lineHeight="38px"
									fontWeight={300}
									mr="32px"
								>
									{t("games")}
									<br id="title-space" /> {t("availableNow")}:
								</Typography>
							</Grid>
							<Grid item xs={12} md={8} id="swiper-container-landing-gamehub">
								<Swiper slidesPerView={"auto"} spaceBetween={10}>
									<SwiperSlide>
										<Game
											title="Fortnite"
											background={`${myStorageUrl}images/landing/fortnite_background.png`}
											logo={`${myStorageUrl}images/landing/fortnite_logo.png`}
										/>
									</SwiperSlide>
									<SwiperSlide>
										<Game
											title="DOTA2"
											background={`${myStorageUrl}images/landing/dota_background.png`}
											logo={`${myStorageUrl}images/landing/dota_logo.png`}
										/>
									</SwiperSlide>
								</Swiper>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
		</>
	);
};
