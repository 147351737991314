import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { LeaderboardContext } from "../../Providers/LeaderboardProvider";
import { PulseDiv } from "../../Tutorial/Tutorial";
import { QuestButtonProps } from "../../../types/Props";

export const QuestButton = ({ title, percentile, color, subtitle, freemiumReward, premiumReward, isPremiumOnly = false }: QuestButtonProps) => {
	const { loggedUserInLeader } = useContext(LeaderboardContext);
	const [activateEffect, setActivateEffect] = useState(false);
	const [displayReward, setDisplayReward] = useState(false);
	const { t } = useTranslation("dashboard");

	useEffect(() => {
		if (loggedUserInLeader !== undefined && Object.keys(loggedUserInLeader).length > 0) {
			if (loggedUserInLeader.percentile === percentile) {
				setActivateEffect(true);
			}
		}
	}, [loggedUserInLeader]);

	return (
		<div
			onClick={() => setDisplayReward(true)}
			onMouseEnter={() => setDisplayReward(true)}
			onMouseLeave={() => setDisplayReward(false)}
			style={{ margin: "0 2em" }}>
			<PulseDiv pulse={activateEffect} color={"white"} className={`button-quest ${color}`}>
				<Grid width={{ xs: "70vw", md: "20vw" }} height={"100%"} container direction="column" alignItems="center" justifyContent="center">
					<Grid item>
						<h3 style={{ fontWeight: "normal" }} className="text-h3">
							{!displayReward ? title : t("rewards")}
						</h3>
					</Grid>
					<Grid item margin={{ xs: "0", md: displayReward ? ".5em 0" : "0" }}>
						{!displayReward ? (
							<>
								<span className="text-label">{subtitle}</span>
								{isPremiumOnly && (
									<p className="text-label text-center mt-2">
										<Trans
											i18nKey="premium.users.only" // optional -> fallbacks to defaults if not provided
											defaults={t("premium.users.only")} // optional defaultValue
											components={{
												span: <span />,
												custom: (
													<span
														style={{
															color: "white",
															opacity: 1,
															fontWeight: "bold",
														}}
													/>
												),
											}}
										/>
									</p>
								)}
							</>
						) : (
							<>
								{!isPremiumOnly && (
									<h5 style={{ opacity: 0.7 }}>
										<b>{t("freemium.user")}</b>: {freemiumReward}
									</h5>
								)}
								<h5 style={{ opacity: 0.7 }}>
									<b>{t("premium.user")}</b>: {premiumReward}
								</h5>
							</>
						)}
					</Grid>
				</Grid>
			</PulseDiv>
		</div>
	);
};
