import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import "../../../../styles/dashboard/market/giftcard.scss";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";
import { Inventory } from "../../../../types/Payments";
import { DialogConfirmBuyGenericCard } from "./DialogConfirmBuyDialog";
import { UnauthorizedDialog } from "../../UnauthorizedDialog";
import { AuthContext } from "../../../Providers/AuthProvider";
import { SettingsContext } from "../../../Providers/SettingsProvider";
import { ItemImageMarketCard } from "./ItemImageMarketCard";

type GenericCardProps = {
	rewardItem: Inventory.Reward;
};

const StyledCard = styled(Card)`
	width: 70%;
	height: 100%;
	position: relative;
`;

export const GenericCard = ({ rewardItem }: GenericCardProps) => {
	const { service, reward, description, image, items } = rewardItem;
	const { t: tDashboard } = useTranslation("dashboard");
	const isMobile = useMediaQuery("(max-width: 900px)");
	const { myStorageUrl } = useContext(FirebaseContext);
	const { profile } = useContext(AuthContext);
	const { language } = useContext(SettingsContext);

	const [showGiftcardDialog, setShowGiftcardDialog] = useState(false);
	const [indexSelectedItem, setIndexSelectedItem] = useState(0);
	const [isUnauthorizedDialogOpen, setIsUnauthorizedDialogOpen] = useState(false);

	const handleClickOpen = () => {
		if (isMobile) {
			handleBuy();
		}
	};

	const handleBuy = () => {
		if (profile === null) setIsUnauthorizedDialogOpen(true);
		else setShowGiftcardDialog(true);
	};

	const handleClose = () => {
		setShowGiftcardDialog(false);
	};

	const title = (
		<>
			<b>{service}</b> {reward}
		</>
	);

	return (
		<>
			<UnauthorizedDialog open={isUnauthorizedDialogOpen} handleClose={() => setIsUnauthorizedDialogOpen(false)} />
			<DialogConfirmBuyGenericCard
				key={items[indexSelectedItem].name.toLowerCase()}
				open={showGiftcardDialog}
				handleClose={() => handleClose()}
				card={{
					category: items[indexSelectedItem].category,
					title: title,
					serviceName: service,
					itemName: reward,
					reward: items[indexSelectedItem].name,
					price: items[indexSelectedItem].price,
					priceInBolts: items[indexSelectedItem].priceInBolts,
					uid: items[indexSelectedItem].uid,
				}}
			/>
			<StyledCard>
				<Grid container justifyContent="center" alignItems="center" flexDirection="row" p={{ xs: 1, lg: 2 }} pr={{ lg: 2 }}>
					{/* IMAGE */}
					<Grid item xs={12} lg={6} minHeight="100%" textAlign={"center"}>
						<ItemImageMarketCard
							src={`${myStorageUrl}images/market/${items[indexSelectedItem].image ?? image}`}
							alt={`${service} ${reward} gift card`}
							onClick={handleClickOpen}
							sx={{
								width: {
									xs: "60%",
									md: "400px",
								},
							}}
						/>
					</Grid>

					{/* CONTENT */}
					<Grid
						item
						xs={12}
						lg={6}
						height={{ xs: "auto", lg: "300px" }}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						py={2}
						px={3}
						gap={{ xs: 5, lg: 2 }}>
						<Typography variant="h5" fontFamily="sora" fontSize="30px" lineHeight="38px" gap={2}>
							{title}
						</Typography>
						<Grid container gap={2}>
							{description && (
								<Box component="div" sx={{ overflowY: "scroll", maxHeight: "80px" }} dangerouslySetInnerHTML={{ __html: description[language] }}>
									{/* <span style={{ whiteSpace: "pre-line" }}>{description[language]}</span> */}
								</Box>
							)}
							<Grid item xs={12} display="flex" gap={2}>
								{items.map((item, index) => (
									<button
										style={{ whiteSpace: "pre-line" }}
										className={`giftcard-button ${indexSelectedItem === index ? "amount-selected" : ""}`}
										onClick={() => {
											setIndexSelectedItem(index);
										}}>
										{item.name}
									</button>
								))}
							</Grid>
							<Grid item xs={12}>
								<Typography fontFamily="sora" fontSize="12px" textTransform="capitalize">
									{tDashboard("total")}: € {(items[indexSelectedItem].price / 100).toFixed(2)} + VAT
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								display="flex"
								justifyContent={isMobile ? "center" : "flex-end"}
								flexWrap="wrap"
								position="relative"
								top={isMobile ? "2em" : undefined}>
								<Box
									component="div"
									minWidth="166px"
									position="relative"
									display="flex"
									justifyContent="center"
									alignItems="center"
									flexBasis={isMobile ? "100%" : undefined}
									maxWidth={isMobile ? "200px" : undefined}>
									<Box
										component="button"
										border="1px solid transparent"
										padding="8px 20px"
										margin={{ xs: "0 0 10px 0", md: "3px" }}
										fontSize="16px"
										borderRadius="50px"
										color="#000000"
										minWidth="166px"
										height="50px"
										flexBasis={isMobile ? "100%" : undefined}
										maxWidth={isMobile ? "200px" : undefined}
										textTransform="uppercase"
										sx={{
											background: "#FFFFFF",
											transition: "background 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out",
											"&:hover": {
												background: "transparent",
												color: "#FFFFFF",
												border: "1px solid #FFFFFF",
											},
										}}
										onClick={handleBuy}>
										{tDashboard("market.generic.card.cta")}
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</StyledCard>
		</>
	);
};
