import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { getPrivacyPolicies } from "../../utilities/policies/getPrivacyPoliciesLinks";

export const CheckPolicies = ({ onChange }: { onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }) => {
	const { t } = useTranslation("dashboard");
	const { storagePicturesUrl } = useContext(FirebaseContext);

	return (
		<>
			<label id={"confirm-privacy-policies-text"}>
				{t("i accept the")}
				<a className={"privacy-policies-link"} href={`${storagePicturesUrl}privacy_policies/${getPrivacyPolicies()}`} target="_blank" rel="noreferrer" title="Privacy Policy">
					{t("privacy policy")}
				</a>
			</label>
			<label className="checkbox" style={{ opacity: 1, background: 0 }}>
				<input type="checkbox" id={"confirm-privacy-policies-checkbox"} name={"confirm-privacy-policies-checkbox"} onChange={onChange} />
				<span></span>
			</label>
			
		</>
	);
};
