import { useEffect } from "react";
import { Grid, ThemeProvider, Typography, createTheme } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../Providers/AuthProvider";

import { QuestContent } from "./QuestContent";
import Countdown from "react-countdown";
import { DisclamerHover } from "../../DisclamerHover";
import { QuestProgressionCardProps } from "../../../../types/Props";

import { defaultDailyQuests, defaultMonthlyQuests } from "../../../../utilities/costants/defaultQuests";
import { SettingsContext } from "../../../Providers/SettingsProvider";
import $ from "jquery";
declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

export const QuestProgressionCard = (props: QuestProgressionCardProps): JSX.Element => {
	const { t } = useTranslation("dashboard");
	const [timeDifference, setTimeDifference] = useState(0);
	const [disclamerOpen, setDisclamerOpen] = useState(false);
	const { changeActiveDashboard } = useContext(SettingsContext);
	const { dailyQuests, monthlyQuests, profile } = useContext(AuthContext);

	useEffect(() => {
		setTimeDifference(props.timeDifference);
	}, [props.timeDifference]);

	useEffect(() => {
		if (profile != null && !profile.isPremium) {
			$(".quest-premium-wrapper").css("filter", "blur(3px)");
		}
	}, [profile]);

	const htmlHover = (
		<>
			{t("quest explanation")}
			<br />
			<br />
			<Typography color="inherit">{t("rewards")}</Typography>
			<ul>
				<li>{t("diamond")} : 4.402516 Bolts</li>
				<li>{t("platinum")} : 2.935010 Bolts</li>
				<li>{t("golden")} : 2.201258 Bolts</li>
				<li>{t("silver")} : 1.446541 Bolts</li>
			</ul>
		</>
	);

	return (
		<div className={`quest-container-premium `}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={5}>
					<h3 style={{ whiteSpace: "nowrap", display: "flex" }}>{props.title}</h3>
				</Grid>
				<Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
					<DisclamerHover
						placement={"bottom-end"}
						htmlHover={htmlHover}
						disclamerOpen={disclamerOpen}
						setDisclamerOpen={setDisclamerOpen}
					></DisclamerHover>
				</Grid>
			</Grid>
			<hr className="border-line" />
			{profile != null && profile.isPremium && (
				<div
					style={{
						color: "white",
						font: "Sora",
						fontWeight: "300",
						fontSize: "20px",
						lineHeight: "20px",
						alignContent: "center",
						boxSizing: "border-box",
						gap: "10px",
						border: "1px solid rgba(255, 255, 255, 0.5)",
						borderRadius: "20px",
						padding: "12px 24px",
						marginBottom: "1em",
						zIndex: 100,
					}}
				>
					{props.type == "daily" && timeDifference != 0 && <Countdown date={Date.now() + timeDifference} daysInHours={true} />}
					{props.type == "monthly" && timeDifference != 0 && <Countdown date={profile.premiumEndDate * 1000} zeroPadDays={2} />}
				</div>
			)}
			<ThemeProvider theme={theme}>
				<Grid container justifyContent={"center"} sx={{ height: "100%" }}>
					<Grid item {...{ xs: 12, sm: 10, screen: 12 }} sx={{ width: "100%", height: "100%", position: "relative" }}>
						<Grid className="quest-premium-wrapper" container columns={16} justifyContent={"center"}>
							{props.type == "daily" ? (
								(profile.favouriteGame != null && profile != null && profile.isPremium) ? (
									dailyQuests.map((q) => {
										const id = `daily-quest-${q.game}-${q.statsForQuest}-${q.level}`;
										const idBackground = `daily-quest-background-${q.game}-${q.statsForQuest}-${q.level}`;
										if (q.game == profile.favouriteGame)
											return (
												<QuestContent
													dailyPoints={q.dp}
													descriptions={q.descriptions}
													description={q.description}
													type={props.type}
													progression={q.progression}
													level={q.level}
													stats={q.statsForQuest}
													game={q.game}
													id={id}
													idBackground={idBackground}
													key={id}
												/>
											);
									})
								) : (
									<>
										{profile != null && profile.isPremium ? (
											<Grid
												item
												xs={10}
												sx={{ padding: "1em", borderRadius: "16px", borderColor: "white", border: "1px solid" }}
											>
												<p className="text-p no-game-connected-text">
													{t("connect and choose your favourite game to access quests")}
												</p>
											</Grid>
										) : (
											<>
												{defaultDailyQuests.map((q) => {
													return <QuestContent key={q.id} id={q.id} idBackground={q.idBackground} type="daily" />;
												})}
											</>
										)}
									</>
								)
							) : props.type == "monthly" && profile.favouriteGame != null && profile != null && profile.isPremium ? (
								monthlyQuests.map((q) => {
									const id = `monthly-quest-${q.game}-${q.statsForQuest}-${q.level}`;
									const idBackground = `monthly-quest-background-${q.game}-${q.statsForQuest}-${q.level}`;
									if (q.game.includes(profile.favouriteGame))
										return (
											<QuestContent
												isPremiumQuests={true}
												type={props.type}
												descriptions={q.descriptions}
												progression={q.progression}
												level={q.level}
												stats={q.statsForQuest}
												game={profile.favouriteGame}
												id={id}
												idBackground={idBackground}
												key={id}
											/>
										);
								})
							) : (
								<>
									{(profile != null && profile.isPremium && profile.favouriteGame == null) ? (
										<Grid item xs={10} sx={{ padding: "1em", borderRadius: "16px", borderColor: "white", border: "1px solid" }}>
											<p className="text-p no-game-connected-text">
												{t("connect and choose your favourite game to access quests")}
											</p>
										</Grid>
									) : (
										<>
											{defaultMonthlyQuests.map((q) => {
												return <QuestContent isPremiumQuests={true} key={q.id} id={q.id} idBackground={q.idBackground} type="montlhy" />;
											})}
										</>
									)}
								</>
							)}
						</Grid>
						{(profile != null && !profile.isPremium) && (
							<Grid
								item
								xs={8}
								sx={{ padding: "1em", borderRadius: "16px", borderColor: "white", border: "1px solid", fontSize: "14px" }}
								className="unlock-premium-features"
							>
								<a
									className="text-p no-game-connected-text"
									onClick={() => {
										changeActiveDashboard("market");
									}}
								>
									{t("upgrade to premium")}
								</a>
							</Grid>
						)}
					</Grid>
				</Grid>
			</ThemeProvider>
		</div>
	);
};
