import { Box, Grid, Tooltip } from "@mui/material";
import { useContext } from "react";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";
import { LeaderItemProps } from "../../../../types/Props";
import { PlayerLeaderboardDota } from "../../../../types/Leaderboard";
import { gamesInStorm } from "../../../../utilities/costants/genericCostants";
import { useTranslation } from "react-i18next";

export const LeaderItem = (props: LeaderItemProps): JSX.Element => {
	const { player, image, username, index, game } = props;
	const { storagePicturesUrl } = useContext(FirebaseContext);
	const { t } = useTranslation("dashboard");

	return (
		<>
			<Grid container columns={12} key={index} className="leaderboard-row" alignItems="center">
				{/* POSITION */}
				<Grid data-testid={"position-standard"} item xs={1} md={1} lg={1}>
					{player.position !== undefined ? player.position + 1 : 0}
				</Grid>

				{/* IMAGE */}
				<Grid item md={1} lg={1} display={{ xs: "none", md: "block", lg: "block" }}>
					<img src={`${storagePicturesUrl}${image}`} className="user-pic" alt={`${player.username.toLowerCase()} profile pic`} />
				</Grid>

				{/* USERNAME */}
				<Grid
					data-testid={"username-standard"}
					item
					xs={5}
					md={5}
					lg={game === gamesInStorm.dota ? 4 : 6}
					className="leaderboard-username"
					sx={{ userSelect: "none" }}>
					{player.premium ? (
						<Tooltip placement="top" title={`${username} ${t("is a Premium user")}`}>
							<span
								style={{
									color: "transparent",
									backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
									backgroundClip: "text",
									WebkitBackgroundClip: "text",
									WebkitTextFillColor: "transparent",
									fontWeight: "bold",
									cursor: "help",
								}}>
								{username}
							</span>
						</Tooltip>
					) : (
						username
					)}
				</Grid>

				{/* RANK */}
				{game === gamesInStorm.dota && (
					<Grid data-testid={"rank-standard"} item lg={2} display={{ xs: "none", md: "none", lg: "block" }}>
						{(player as PlayerLeaderboardDota).rank}
					</Grid>
				)}

				{/* DAILY POINTS */}
				<Grid data-testid={"points-standard"} item xs={3} md={2} lg={2}>
					{player.dailyPoints}
				</Grid>

				{/* DAILY BOLTS */}
				<Grid data-testid={"bolts"} item xs={3} md={3} lg={2}>
					{player.bolts}
				</Grid>
				<hr className="mobile-line" />
			</Grid>
		</>
	);
};
