import { Grid, Typography } from "@mui/material";
import "../../../../styles/home/GamehubLanding.scss";

export const Game = ({ title, background, logo }: { title: string; background: string; logo: string }) => {
	return (
		<Grid
			container
			flexDirection="column"
			alignItems="center"
			justifyContent="flex-end"
			sx={{ position: "relative", height: "300px" }}
			className="game-container"
			id={`${title.toLowerCase()}-game-hub-container`}
		>
			<img src={background} id={`${title.toLowerCase()}-gamehub-background`} className="background-gamehub-game animated" />
			<img src={logo} id={`${title.toLowerCase()}-gamehub-logo`} className="logo-gamehub-game animated" />
			<Grid item xs={12} display="flex" alignItems="flex-end" className="content-gamehub-game animated" >
				<Typography
					variant="h6"
					mb="32px"
					fontWeight={500}
					fontSize="24px"
					lineHeight="21px"
					sx={{ opacity: "1 !important", zIndex: "200" }}
				>
					{title}
				</Typography>
			</Grid>
		</Grid>
	);
};
