import { useContext } from "react";

import { SettingsContext } from "../Providers/SettingsProvider";
import { SignupForm } from "./SignupForm";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { GenericProps } from "../../types/Props";

export const Signup = (props: GenericProps): JSX.Element => {
	const { language } = useContext(SettingsContext);
	const { myStorageUrl } = useContext(FirebaseContext);

	return (
		<>
			<div className="singup-container">
				<video loop={true} autoPlay={true} muted={true} className="signup-video" id={"video-container-signup"}>
					<source src={`${myStorageUrl}videos/video_background.mp4`}></source>
				</video>

				<SignupForm language={language} showToast={props.showToast}></SignupForm>
			</div>
		</>
	);
};
