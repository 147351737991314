import { useContext, useEffect, useState } from "react";
import { collection, doc, orderBy, query, getDocs, deleteDoc, CollectionReference } from "firebase/firestore";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { AuthContext } from "../../Providers/AuthProvider";
import { Box, Grid } from "@mui/material";
import { GiftCardRequest, GiftCardRequestData, RewardRequest, RewardRequestData } from "../../../types/GiftCard";
import { InputText } from "../../Input/InputText";
import { Field, FieldArray, Form, Formik } from "formik";
import { Inventory } from "../../../types/Payments";
import { useSnackbar } from "../../Providers/SnackbarProvider";
import { NetworkContext } from "../../Providers/NetworkProvider";
import { InventoryAPI } from "../../../utilities/network/api";
import { MarketContext } from "../../Providers/MarketProvider";

const itemInitialValue = {
	name: "",
	price: 0,
	priceInBolts: 0,
	VAT: 0,
	image: "",
	uid: 0,
	category: ""
};

const initialValues = {
	service: "",
	reward: "",
	description: {
		en: "",
		it: "",
	},
	image: "",
	category: "",
	items: [itemInitialValue],
};

export const DashboardAdminRewards = (): JSX.Element => {
	const { myFS } = useContext(FirebaseContext);
	const { profile } = useContext(AuthContext);
	const { makePost } = useContext(NetworkContext);
	const { getItemsFromDB, categories } = useContext(MarketContext);
	const { openSnackbar } = useSnackbar();

	const [show, setShow] = useState(false);
	const [rewards, setRewards] = useState<Inventory.Reward[]>([] as Inventory.Reward[]);
	const [rewardsRequests, setRewardsRequests] = useState([] as RewardRequest[]);

	const getRewardItems = async () => {
		const items = await getItemsFromDB();
		setRewards(items.rewards);
	};

	const handleRewardsRequests = async (): Promise<void> => {
		try {
			const docRef = query<RewardRequestData>(
				collection(myFS, "rewardRequests") as CollectionReference<RewardRequestData>,
				orderBy("creationDate", "desc")
			);
			const querySnap = await getDocs<RewardRequestData>(docRef);
			let rewardsRequests = [] as RewardRequest[];
			let index = 0;

			querySnap.forEach((doc) => {
				rewardsRequests.push({ ...doc.data(), id: doc.id, index: index } as RewardRequest);
				index = index + 1;
			});
			console.log(rewardsRequests);
			setRewardsRequests(rewardsRequests);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleRewardsRequests();
		getRewardItems();
	}, [profile?.idToken, myFS]);

	async function deleteRewardRequest(requestId: string): Promise<void> {
		var answer = window.confirm("Are you sure to delete the request reward item with id : " + requestId);
		if (answer) {
			//Delete request on the DB
			await deleteDoc(doc(myFS, `rewardRequests/${requestId}`));
			//Delete the request locally
			const rewardsRequestsTMP = [...rewardsRequests];
			const requestReward = rewardsRequests.find(({ id }) => {
				return id === requestId;
			});
			const index = rewardsRequestsTMP.indexOf(requestReward);
			rewardsRequestsTMP.splice(index, 1);
			setRewardsRequests(rewardsRequestsTMP);
		} else {
		}
	}

	async function addRewardItem(rewardItem: Inventory.Reward | null, category: string): Promise<void> {
		try {
			if (category.length === 0) {
				openSnackbar("Error", "Please select a category", "error");
				return;
			}
			for (let i = 0; i < rewards.length; i++) {
				for (let j = 0; j < rewards[i].items.length; j++) {
					if (rewards[i].items[j] != null) {
						rewards[i].items[j].uid = j;
					}
				}
			}
			if (rewardItem == null) {
				openSnackbar("Error", "Please specify a reward", "error");
				return;
			}

			rewardItem.items.forEach((item) => {
				item.category = category;
			});

			const response = await makePost(`${InventoryAPI.Admin.addRewardItem}`, {
				rewardItem: rewardItem,
				_token: `Bearer ${profile.idToken}`,
			});

			if (response != null && response.status === 200) {
				openSnackbar("Success", response.data.message, "success");
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", response.data.message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	async function removeRewardItem(rewardItem: Inventory.Reward): Promise<void> {
		try {
			const response = await makePost(`${InventoryAPI.Admin.removeRewardItem}`, {
				rewardItem: rewardItem,
				_token: `Bearer ${profile.idToken}`,
			});

			if (response != null && response.status === 200) {
				openSnackbar("Success", response.data.message, "success");
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", response.data.message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	async function editRewardItem(rewardItem: Inventory.Reward, index: number): Promise<void> {
		try {
			const response = await makePost(`${InventoryAPI.Admin.editRewardItem}`, {
				rewardItem: rewardItem,
				index: index,
				_token: `Bearer ${profile.idToken}`,
			});

			if (response != null && response.status === 200) {
				openSnackbar("Success", response.data.message, "success");
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", response.data.message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	return (
		<>
			<button type="button" className="btn-no-fill" style={{ width: "20%" }} onClick={() => setShow(!show)}>
				<h3>{show ? "Hide" : "Show"} Rewards requests</h3>
			</button>
			<div style={{ display: show ? "flex" : "none", flexDirection: "column", alignItems: "center" }}>
				<Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
					<h1>Add new Reward Item:</h1>
					<Formik
						initialValues={initialValues}
						onSubmit={async (values) => {
							// check if every field is not empty
							if (
								values.service === "" ||
								values.reward === "" ||
								values.description.en === "" ||
								values.description.it === "" ||
								values.image === "" ||
								values.category === ""
							) {
								openSnackbar("Error", "Please fill all the fields", "error");
								return;
							}
							// check if every field of the items is not empty
							for (let i = 0; i < values.items.length; i++) {
								if (values.items[i].name === "" || values.items[i].price === 0 || values.items[i].priceInBolts === 0) {
									openSnackbar("Error", "Please fill all the fields", "error");
									return;
								}

								if (values.items[i].image.length === 0) {
									delete values.items[i].image;
								}

								values.items[i].uid = i;
							}

							const rewardItem: Inventory.Reward = {
								service: values.service,
								reward: values.reward,
								description: values.description,
								image: values.image,
								items: values.items
							};
							await addRewardItem(rewardItem, values.category);
							await getRewardItems();
						}}>
						{({ values }) => (
							<Form style={{ width: "60%" }}>
								<Grid container gap={1} margin="auto">
									<Grid item display="flex" gap={1}>
										<Field name="service" placeholder="Service Name" type="text" />
										<Field name="reward" placeholder="Item Name" type="text" />
										<Field name="description.en" placeholder="ENG Description" as="textarea" />
										<Field name="description.it" placeholder="ITA Description" as="textarea" />
										<Field name="image" placeholder="Image Path" type="text" />
										<Field name="category" as="select" id="category">
											<option value={values.category}>Select Category</option>
											{
												Object.values(categories).map((category) => {
													return <option key={category} value={category}>{category}</option>
												})
											}
										</Field>
									</Grid>

									<Grid container>
										<FieldArray name="items">
											{({ insert, remove, push }) =>
												values.items.length > 0 &&
												values.items.map((item, index) => (
													<Grid item key={index} display="flex" gap={1} mb={1}>
														<Field name={`items.${index}.name`} placeholder="Reward Name" type="text" />
														<Field name={`items.${index}.price`} placeholder="Price" type="number" />
														<Field name={`items.${index}.VAT`} placeholder="VAT" type="number" />
														<Field name={`items.${index}.priceInBolts`} placeholder="Price in Bolts" type="number" />
														<Field name={`items.${index}.image`} placeholder="Image Path" type="text" />
														<button
															type="button"
															className="secondary"
															onClick={() => {
																remove(index);
																if (index === 0 && values.items.length === 1) push(itemInitialValue);
															}}>
															❌
														</button>
														{index === values.items.length - 1 && (
															<button type="button" className="secondary" onClick={() => push(itemInitialValue)}>
																➕
															</button>
														)}
													</Grid>
												))
											}
										</FieldArray>
									</Grid>
									<input type="submit" value="Submit" />
								</Grid>
							</Form>
						)}
					</Formik>
				</Grid>
				<Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
					<h1>Remove/Edit Reward Item:</h1>
					<Formik
						enableReinitialize={true}
						initialValues={{
							rewards: rewards || [],
						}}
						onSubmit={(values) => {
							console.log(values);
						}}>
						{({ values }) => (
							<FieldArray name="rewards">
								{({ insert, remove, push }) =>
									values.rewards.length > 0 &&
									values.rewards.map((reward, indexR) => (
										<>
											<table style={{ borderColor: "white" }}>
												<thead>
													<tr>
														<th>Service</th>
														<th>Reward</th>
														<th>ENG Description</th>
														<th>ITA Description</th>
														<th>Default Image</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<Field name={`rewards.${indexR}.service`} placeholder="Service Name" type="text" />
														</td>
														<td>
															<Field name={`rewards.${indexR}.reward`} placeholder="Item Name" type="text" />
														</td>
														<td>
															<Field name={`rewards.${indexR}.description.en`} placeholder="ENG Description" as="textarea" />
														</td>
														<td>
															<Field name={`rewards.${indexR}.description.it`} placeholder="ITA Description" as="textarea" />
														</td>
														<td>
															<Field name={`rewards.${indexR}.image`} placeholder="Image Path" type="text" />
														</td>
													</tr>
													<tr>
														<td colSpan={6}>
															<table>
																<thead>
																	<tr>
																		<th>Item Name</th>
																		<th>Price</th>
																		<th>VAT</th>
																		<th>Price in Bolts</th>
																		<th>Image Path</th>
																	</tr>
																</thead>
																<tbody>
																	<FieldArray name={`rewards[${indexR}].items`}>
																		{({ insert, remove: removeI, push }) =>
																			reward.items.length > 0 &&
																			reward.items.map((item, indexI) => (
																				<tr>
																					<td>
																						<Field name={`rewards.${indexR}.items.${indexI}.name`} placeholder="Reward Name" type="text" />
																					</td>
																					<td>
																						<Field name={`rewards.${indexR}.items.${indexI}.price`} placeholder="Price" type="number" />
																					</td>
																					<td>
																						<Field name={`rewards.${indexR}.items.${indexI}.VAT`} placeholder="VAT" type="number" />
																					</td>
																					<td>
																						<Field
																							name={`rewards.${indexR}.items.${indexI}.priceInBolts`}
																							placeholder="Price in Bolts"
																							type="number"
																						/>
																					</td>
																					<td>
																						<Field name={`rewards.${indexR}.items.${indexI}.image`} placeholder="Image Path" type="text" />
																					</td>
																					<td style={{ display: "flex", gap: 5 }}>
																						<button
																							type="button"
																							className="secondary"
																							style={{
																								alignSelf: "center",
																							}}
																							onClick={() => {
																								removeI(indexI);
																							}}>
																							❌
																						</button>
																					</td>
																				</tr>
																			))
																		}
																	</FieldArray>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<Grid container>
												<Box
													component="button"
													type="button"
													sx={{
														backgroundColor: "secondary.main",
														color: "white",
														border: 0,
														borderRadius: 2,
														boxShadow: 1,
														cursor: "pointer",
														fontSize: 16,
														fontWeight: "bold",
														lineHeight: 1.5,
														padding: "10px 20px",
														margin: "10px",
														"&:hover": {
															backgroundColor: "secondary.dark",
														},
													}}
													onClick={() => editRewardItem(values.rewards[indexR], indexR)}>
													SAVE EDIT 💾
												</Box>
												<Box
													component="button"
													type="button"
													sx={{
														backgroundColor: "warning.main",
														color: "white",
														border: 0,
														borderRadius: 2,
														boxShadow: 1,
														cursor: "pointer",
														fontSize: 16,
														fontWeight: "bold",
														lineHeight: 1.5,
														padding: "10px 20px",
														margin: "10px",
														"&:hover": {
															backgroundColor: "warning.dark",
														},
													}}
													onClick={() => {
														remove(indexR);
														removeRewardItem(reward);
													}}>
													DELETE REWARD
												</Box>
											</Grid>
											<hr />
										</>
									))
								}
							</FieldArray>
						)}
					</Formik>
				</Grid>

				<h1>Rewards requests : </h1>
				<Grid container>
					<Grid item xs={12} sx={{ overflowX: "auto", margin: "2em" }}>
						{rewardsRequests != null && rewardsRequests !== undefined && rewardsRequests.length !== 0 && (
							<>
								<table>
									<thead>
										<tr>
											<th>
												<h5>CREATION DATE</h5>
											</th>
											<th>
												<h5>/rewardRequests/{"{id}"}</h5>
											</th>
											<th>
												<h5>USER</h5>
											</th>
											<th>
												<h5>REWARD</h5>
											</th>
											<th>
												<h5>STATUS</h5>
											</th>
											<th>
												<h5>DELETE REQUEST</h5>
											</th>
										</tr>
									</thead>
									<tbody>
										{rewardsRequests.map((rewardRequest) => {
											return (
												<tr>
													<td>
														<p className="paragraph-no-wrap">{new Date(rewardRequest.creationDate * 1000).toUTCString()}</p>
													</td>
													<td>{rewardRequest.id}</td>
													<td>
														<table>
															<tr>
																<td>Email: {rewardRequest.user.email}</td>
															</tr>
															<tr>
																<td>Username: {rewardRequest.user.username}</td>
															</tr>
															<tr>
																<td>UID: {rewardRequest.user.uid}</td>
															</tr>
														</table>
													</td>
													<td>
														<table>
															<tr>
																<td>Service: {rewardRequest.rewardItem.serviceName}</td>
															</tr>
															<tr>
																<td>Item Name: {rewardRequest.rewardItem.itemName}</td>
															</tr>
															<tr>
																<td>Reward: {rewardRequest.rewardItem.reward}</td>
															</tr>
															<tr>
																<td>Price: €{rewardRequest.rewardItem.price / 100}</td>
															</tr>
															<tr>
																<td>Price in bolts: {rewardRequest.rewardItem.priceInBolts}</td>
															</tr>
														</table>
													</td>
													<td>{rewardRequest.status}</td>
													<td>
														<button onClick={() => deleteRewardRequest(rewardRequest.id)}>X</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</>
						)}
					</Grid>
				</Grid>
			</div>
		</>
	);
};
