import { createContext, useEffect, useState } from "react";
import { SettingsProviderData } from "../../types/Providers";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { useLocation } from "react-router-dom";
export const SettingsContext = createContext<SettingsProviderData | null>(null);

export const SettingsProvider = (props): JSX.Element => {
	const children = props.children;
	const search = useLocation().search;

	(i18n as any)
		.use(Backend) //load translations via xhr from /public/locales
		.use(LanguageDetector) //automatically detect browser language
		.use(initReactI18next) //this will make i18n available from ALL components automatically
		.init({
			//standard i18next initialization object
			fallbackLng: "en",
			interpolation: {
				escapeValue: false, //react escapes by default
			},
			react: {
				wait: true,
				useSuspense: false, //this at true would force us to create a suspense component in the root of the app, which would be a bit ugly
			},
		});

	const epicGamesUrl = (): string => {
		const client_id = "xyza7891x4HPEtKeBiX9ompSep82tZWS";
		const redirect_uri = "https://storm.co.gg/dashboard/";
		return `https://www.epicgames.com/id/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=basic_profile`;
	};

	const [language, setLanguage] = useState(window.localStorage.getItem("language") || "en");
	const [activeDashboard, setActiveDashboard] = useState("home");

	const changeLanguage = (lang: string): void => {
		setLanguage(lang);
		i18n.changeLanguage(lang);
		window.localStorage.setItem("language", lang);
	};

	const changeActiveDashboard = (newActive: string): void => {
		setActiveDashboard(newActive);
	};

	useEffect(() => {
		if (shouldOpenMarket()) {
			changeActiveDashboard("market");
		}
	}, []);

	const shouldOpenMarket = () => {
		let code = new URLSearchParams(search).get("page");

		return code === "market";
	};

	const settingsProviderData: SettingsProviderData = {
		language,
		changeLanguage: changeLanguage,
		activeDashboard,
		changeActiveDashboard: changeActiveDashboard,
		epicGamesUrl: epicGamesUrl,
	};

	return <SettingsContext.Provider value={settingsProviderData}>{children}</SettingsContext.Provider>;
};
