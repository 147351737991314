import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type UnauthorizedDialogProps = {
	open: boolean;
	handleClose: () => void;
};

export const UnauthorizedDialog = ({ open, handleClose }: UnauthorizedDialogProps) => {
	const { t } = useTranslation("dashboard");

	return (
		<>
			<Box
				component={Dialog}
				sx={{
					"& .MuiPaper-root": {
						backgroundColor: "rgba(136, 9, 70, 0.95) !important",
						boxShadow: "0px 0px 20px 5px rgba(136,9,70,1)",
					},
				}}
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" fontSize={25} fontWeight="bold" sx={{ paddingTop: 0 }}>
					{t("sign up")} {t("or")} {t("login")}
				</DialogTitle>
				<DialogContent
					style={{
						flexGrow: 0,
					}}>
					<p className="text-p" style={{ textAlign: "center" }}>
						{t("market.unauthorized.buy")}
					</p>
				</DialogContent>

				<DialogActions sx={{ justifyContent: "center" }}>
					<Box
						component={Link}
						sx={{
							height: "45px",
							width: "30%",
							marginLeft: 0,
							textDecoration: "none",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							textTransform: "uppercase",
						}}
						className="btn-fill text-p"
						to="/login">
						{t("login")}
					</Box>
					<Box
						component={Link}
						sx={{
							height: "45px",
							width: "30%",
							border: "1px solid #000",
							marginLeft: 0,
							textDecoration: "none",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							textTransform: "uppercase",
							"&:hover": {
								border: "1px solid white",
							},
						}}
						className="btn-no-fill text-p"
						to="/signup">
						{t("sign up")}
					</Box>
				</DialogActions>
			</Box>
		</>
	);
};
