import { Grid, Typography, useMediaQuery, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table } from "@mui/material";
import { Header } from "../../components/HomepageComponents/Header";
import { getPrivacyPolicies } from "../../utilities/policies/getPrivacyPoliciesLinks";
import { FooterLanding } from "../../components/home/landing/FooterLanding";
import { useContext } from "react";
import { FirebaseContext } from "../../components/Providers/FirebaseProvider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

const pp = [
	{
		date: new Date(2023, 6, 18),
		fileName: {
			eng: "PP_18_07_2023_ENG.pdf",
			ita: "PP_18_07_2023_ITA.pdf",
		},
	},
];

const styles = makeStyles({
	table: {
		"& .MuiTableCell-root": {
			color: "white",
		},
	},
});

export const PrivacyPolicies = () => {
	const { storagePicturesUrl } = useContext(FirebaseContext);
	const { t } = useTranslation(["privacy-policies"]);
	const isMobile = useMediaQuery("(max-width: 900px)");
	const useStyles = styles();

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Header />
				</Grid>
			</Grid>
			<Grid container sx={{ top: "5em", position: "relative" }} gap={5}>
				<Grid item xs={12} textAlign={"center"}>
					<Typography variant="h4">{t("title")}</Typography>
				</Grid>
				<Grid item xs={12} textAlign={"center"}>
					<Typography variant="h6">{t("subtitle")}</Typography>
				</Grid>
				<Grid item display="flex" xs={12} justifyContent="center" height="70vh">
					<iframe
						src={`${storagePicturesUrl}privacy_policies/${getPrivacyPolicies()}`}
						title="Term and conditions"
						width={isMobile ? "80%" : "50%"}
						height="100%"></iframe>
				</Grid>
				<Grid container justifyContent="center" gap={5} mt={6}>
					<Grid item xs={12} textAlign="center">
						<Typography variant="h6">{t("historic")}</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<TableContainer component={Paper}>
							<Table aria-label="simple table" className={useStyles.table}>
								<TableHead>
									<TableRow>
										<TableCell align="left">{t('date')}</TableCell>
										<TableCell align="center">ENG</TableCell>
										<TableCell align="center">ITA</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{pp
										.sort((d1, d2) => d2.date.valueOf() - d1.date.valueOf())
										.map((row) => (
											<TableRow key={row.date.valueOf()} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell component="th" scope="row" align="left">
													{row.date.toLocaleDateString()}
												</TableCell>
												<TableCell align="center">
													<a href={`${storagePicturesUrl}privacy_policies/${row.fileName.eng}`} target="_blank" rel="noopener noreferrer">
														{row.fileName.eng}
													</a>
												</TableCell>
												<TableCell align="center">
													<a href={`${storagePicturesUrl}privacy_policies/${row.fileName.ita}`} target="_blank" rel="noopener noreferrer">
														{row.fileName.ita}
													</a>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<FooterLanding />
				</Grid>
			</Grid>
		</>
	);
};
