import { createContext } from "react";
import { PixelsProviderData } from "../../types/Providers";
import { emitRegistrationCompletedEvent } from "../../Javascript/MetaPixels";

export const PixelsContext = createContext<PixelsProviderData | null>(null);
export const PixelsProvider = (props: any): JSX.Element => {
    const children = props.children;
    const PIXEL_EVENTS = { COMPLETE_REGISTRATION: 'CompleteRegistration' };

    async function emitPixelEvent<T>(eventName: string, params: T) {

        if (Object.values(PIXEL_EVENTS).indexOf(eventName) === -1) {
            throw new Error('Invalid pixel event name');
        }
        if (eventName === PIXEL_EVENTS.COMPLETE_REGISTRATION) {
            console.log(`Emitting pixel event ${eventName}`);
            emitRegistrationCompletedEvent();
        }
    }

    const networkProviderData: PixelsProviderData = {
        PIXEL_EVENTS,
        emitPixelEvent
    };

    return <PixelsContext.Provider value={networkProviderData}>{children}</PixelsContext.Provider>;
};
