import { Grid, Typography } from "@mui/material"
import { Header } from "../../components/HomepageComponents/Header"
import { useTranslation } from "react-i18next";
import { ListOfCookiesInfo } from "./ListOfCookiesInfo";
import { FooterLanding } from "../../components/home/landing/FooterLanding";

export const CookiesPage = () => {
    const { t } = useTranslation(['cookies']);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Header />
                </Grid>
            </Grid>
            <Grid container sx={{ top: '5em', position: 'relative' }}>
                <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h4">{t('title')}</Typography>
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h6">{t('subtitle')}</Typography>
                </Grid>
                <Grid container columns={10} padding={'2em'} spacing={2}>
                    <Grid item >
                        <Typography variant="body1">{t('first.paragraph')}</Typography>
                        <Typography variant="body1" paddingTop={'10px'}>{t('first.paragraph.click.here')}</Typography>
                    </Grid>
                    <Grid item >
                        <ListOfCookiesInfo />
                    </Grid>
                    <Grid item>
                        <div id="cookiefirst-policy-page"></div> <div>This cookie policy has been created and updated by <a href="https://cookiefirst.com/consent-management-platform/">Consent Management - CookieFirst</a>.</div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FooterLanding />
                </Grid>
            </Grid>

        </>
    );
}