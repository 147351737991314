import $ from "jquery";
export const Accordion: React.FC<{
	children: React.ReactNode;
	id: string;
	accordionActionElement: JSX.Element;
	removeTitle: boolean;
	accordionOpen: boolean;
	setAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
	children,
	accordionActionElement,
	id,
	removeTitle,
	accordionOpen,
	setAccordionOpen,
}: {
	children: React.ReactNode;
	accordionActionElement: JSX.Element;
	id: string;
	removeTitle: boolean;
	accordionOpen: boolean;
	setAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const handleAccordionToggle = () => {
		if (accordionOpen) {
			setAccordionOpen(false);
			$(`#${id}-button`).addClass("collapsed");

			$(`#${id}`).removeClass("accordion-open");
		} else {
			setAccordionOpen(true);
			$(`#${id}`).addClass("accordion-open");
			$(`#${id}-button`).removeClass("collapsed");
		}
	};
	return (
		<>
			<div style={{ display: "grid" }}>
				<section style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}>
					<div className="accordion-button-customize collapsed" id={`${id}-button`} onClick={handleAccordionToggle}>
						<div className={`${removeTitle ? "remove-title-accordion" : ""}`}>{accordionActionElement}</div>
					</div>
					<article className="accordion-content" id={id}>
						{children}
					</article>
				</section>
			</div>
		</>
	);
};
