import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Inventory } from "../../../../types/Payments";
import { useContext, useState } from "react";
import { AuthContext } from "../../../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { MarketContext } from "../../../Providers/MarketProvider";

type ConfirmBuyDialogProps = {
	open: boolean;
	handleClose: () => void;
	subscriptionInfo: Inventory.SubscriptionItem;
};

export const DialogConfirmBuySubscription = ({ open, handleClose, subscriptionInfo }: ConfirmBuyDialogProps) => {
	const { profile } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");
	const { t: tResponse } = useTranslation("responses");
	const { buySubscriptionWithBolts } = useContext(MarketContext);

	const [isLoading, setIsLoading] = useState(false);

	const handleConfirm = async () => {
		try {
			if (profile !== null) {
				if (profile.isPremium) {
					toast.error(tResponse("user.already.has.subscription"));
					return;
				}

				if (subscriptionInfo.priceInBolts > profile.bolts) {
					toast.error(tResponse("user.doesnt.have.enough.bolts"));
					return;
				}

				setIsLoading(true);
				await buySubscriptionWithBolts();
				setIsLoading(false);
			}
		} catch (e) {
			console.log(e);
			toast.error(e.i18nKey ? t(e.i18nKey) : e.message);
		} finally {
			handleClose();
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title" fontSize={25} fontWeight="bold" sx={{ paddingTop: 0 }}>
				{t("purchasing")} {t(subscriptionInfo.i18nKey)}
			</DialogTitle>
			<DialogContent>{t("are you sure to proceed with the purchase")}</DialogContent>

			<DialogActions sx={{ justifyContent: "center", gap: 3 }}>
				{isLoading ? (
					<CircularProgress
						size={24}
						sx={{
							color: "#dedede",
							top: "50%",
							left: "50%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
				) : (
					<>
						<button onClick={handleClose} style={{ height: "45px", width: "50%", marginLeft: 0 }} type="button" className={`btn-outline text-p`}>
							{t("cancel")}
						</button>
						<button
							onClick={handleConfirm}
							style={{ height: "45px", width: "50%", border: "1px solid #000", marginLeft: 0 }}
							type="button"
							className={`btn-no-fill text-p`}>
							{t("confirm")}
						</button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
