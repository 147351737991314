import { Grid, Typography } from "@mui/material";
import estorm_plus from "../../../../assets/images/estorm_plus.svg";
import subscribed_background from "../../../../subscribed-background.png";
import { useContext } from "react";
import { SettingsContext } from "../../../Providers/SettingsProvider";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../Providers/AuthProvider";

export const SubscriptionSubscribed = () => {
	const { changeActiveDashboard } = useContext(SettingsContext);
	const { t } = useTranslation("dashboard");
	const { language } = useContext(SettingsContext);
	const { profile } = useContext(AuthContext);
	return (
		<>
			<Grid container sx={{ position: "relative" }} className="subscribed-container">
				<img
					alt="background subscribed"
					src={subscribed_background}
					style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
				/>
				<Grid item {...{ xs: 12, lgsmall: 6 }} display="flex" justifyContent="center" alignItems="center" sx={{ zIndex: 2 }}>
					<img alt="estorm plus" src={estorm_plus} className="subscribed-estorm-logo"></img>
				</Grid>
				<Grid
					item
					{...{ xs: 12, lgsmall: 5 }}
					display="flex"
					flexDirection="column"
					rowGap={1}
					sx={{ zIndex: 2 }}
					className="subscribed-card-text-container">
					<Typography className="subscribed-title">
						{t("market.subscribed.card.title#1")}
						{language === "it" ? (
							<b>
								{" "}
								{t("market.subscribed.card.title.user")}{" "}
								<span className="subscribed-title-gradient-text">{t("market.subscribed.card.title.premium")}</span>
							</b>
						) : (
							<b>
								{" "}
								{t("market.subscribed.card.title.user")}{" "}
								<span className="subscribed-title-gradient-text">{t("market.subscribed.card.title.premium")}</span>
							</b>
						)}
					</Typography>
					<Typography className="subscribed-text">
						<a
							style={{ textDecoration: "underline", cursor: "pointer" }}
							onClick={() => {
								changeActiveDashboard("home");
							}}>
							{t("market.subscribed.card.text#1")}
						</a>{" "}
						{t("market.subscribed.card.text#2")}
					</Typography>
					<Typography className="subscribed-text">
						{t("market.subscribed.card.text#3")}{" "}
						{new Date(profile.premiumEndDate * 1000).toLocaleDateString(navigator.language || (navigator.languages || ["en"])[0])}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
