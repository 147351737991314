import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

const CustomOutlinedButtonV2 = styled(Button)(({ theme }) => ({
	backgroundColor: "none",
	border: `1px solid ${theme.palette.secondary.dark}`,
	color: theme.palette.common.white,
	borderRadius: 100,
	textTransform: "unset",
	"&:hover": {
		border: `1px solid ${theme.palette.secondary.main}`,
	},
}));

export const OutlinedButtonV2 = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & { sx: any }>(
	({ sx, onClick, className, children, content, disabled, ...props }, ref) => (
		<CustomOutlinedButtonV2 ref={ref} className={className} sx={sx} onClick={onClick} disabled={disabled}>
			{content ?? children}
		</CustomOutlinedButtonV2>
	)
);
