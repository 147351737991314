import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";

import "../../../styles/home/WhyUs.scss";
import { Accordion } from "../../utils/Accordion";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
export const WhyUs = () => {
	const { t } = useTranslation(["landing"]);
	const elementRef = useRef(null);
	const [animationPlayed, setAnimationPlayed] = useState<boolean>(false);
	const [accordionOpen, setAccordionOpen] = useState<boolean | null>(null);
	const groupRef = useRef<HTMLDivElement>(null);
	const { myStorageUrl } = useContext(FirebaseContext);

	useEffect(() => {
		const handleMouseMovement = (e) => {
			const rect = e.target.getBoundingClientRect();
			const mouseX = e.clientX - rect.left;
			const mouseY = e.clientY - rect.top;

			e.target.style.setProperty("--mouse-x", `${mouseX}deg`);
			e.target.style.setProperty("--mouse-y", `${mouseY}deg`);
		};
		const panel = document.querySelector("#why-us-image");

		panel.addEventListener("mousemove", handleMouseMovement);

		return () => {
			panel.removeEventListener("mousemove", handleMouseMovement);
		};
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					/**Make sure that the timeout time is equal to the animation time */
					if (entry.isIntersecting) {
						if (!entry.target.classList.contains("appear-from-right")) {
							entry.target.classList.add("appear-from-right");
							setTimeout(() => {
								setAnimationPlayed(true);
							}, 2000);
							observer.unobserve(entry.target);
						}
					} else if (false) {
						if (entry.target.classList.contains("appear-from-right")) {
							entry.target.classList.add("disappear-from-right");
							entry.target.classList.remove("appear-from-right");
							setTimeout(() => {
								setAnimationPlayed(false);
								entry.target.classList.remove("disappear-from-right");
							}, 2000);
							observer.unobserve(entry.target);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (elementRef.current) {
			observer.observe(elementRef.current);
		}

		return () => {
			if (elementRef.current) {
				observer.unobserve(elementRef.current);
			}
		};
	}, [animationPlayed]);

	const [textAnimation, setTextAnimation] = useState<boolean>(false);
	const textContainerRef = useRef(null);
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					/**Make sure that the timeout time is equal to the animation time */
					if (entry.isIntersecting) {
						if (!entry.target.classList.contains("appear-from-bottom")) {
							entry.target.classList.add("appear-from-bottom");
							setTimeout(() => {
								setTextAnimation(true);
							}, 2000);
							observer.unobserve(entry.target);
						}
					} else if (false) {
						if (entry.target.classList.contains("appear-from-bottom")) {
							entry.target.classList.add("disappear-from-bottom");
							entry.target.classList.remove("appear-from-bottom");
							setTimeout(() => {
								setTextAnimation(false);
								entry.target.classList.remove("disappear-from-bottom");
							}, 2000);
							observer.unobserve(entry.target);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (textContainerRef.current) {
			observer.observe(textContainerRef.current);
		}

		return () => {
			if (textContainerRef.current) {
				observer.unobserve(textContainerRef.current);
			}
		};
	}, [textAnimation]);
	useEffect(() => {
		const accordionButton = document.getElementById("accordion-why-us-button");
		const group = groupRef.current;

		const layout = () => {
			group.classList.toggle("accordion-text-open");
		};
		accordionButton.addEventListener("click", layout);
		return () => {
			if (accordionButton) {
				accordionButton.removeEventListener("click", layout);
			}
		};
	}, []);

	const handleClick = () => {
		document.getElementById('landing-signup-container').scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<>
			<Grid container id="why-us-container" justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={10}>
					<Grid container justifyContent="center" alignItems="center" sx={{ flexDirection: { xs: "column-reverse", sm: "row" } }}>
						<Grid item xs={10} sm={6} lg={4} xl={6} id="why-us-text-container" ref={textContainerRef}>
							<Typography variant="h3" className="section-text">
								{t("why.us.title")}
							</Typography>
							<div className="container-why-us-call-to-action" id="container-why-us-call-to-action" ref={groupRef}>
								<Accordion
									id="accordion-why-us"
									accordionActionElement={<Typography className="accordion-text">{t("discover.more")}</Typography>}
									removeTitle={true}
									setAccordionOpen={setAccordionOpen}
									accordionOpen={accordionOpen}
								>
									<Typography className="accordion-content-text">{t("why.us.accordion")}</Typography>
								</Accordion>
								<button onClick={handleClick} className="button-landing  join-button">
									{t("button.join.us")}
								</button>
							</div>
						</Grid>
						<Grid
							item
							xs={10}
							sm={6}
							lg={8}
							xl={6}
							className="container-why-us-image"
							ref={elementRef}
							id="why-us-image-container"
							alignSelf="flex-start"
						>
							<img id="why-us-image" className="why-us-image" src={`${myStorageUrl}images/landing/graph_image.png`} alt="Depth Image" />
							<div id="why-us-first-bubble-background"></div>
							<div id="why-us-second-bubble-background"></div>
							<div id="why-us-third-bubble-background"></div>
							<div id="why-us-fourth-bubble-background"></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
