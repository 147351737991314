import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import DropdownList from "react-widgets/DropdownList";

import $ from "jquery";
import "../../../styles/home/Rewards.scss";
import { useTranslation } from "react-i18next";
import { Accordion } from "../../utils/Accordion";
import { FirebaseContext } from "../../Providers/FirebaseProvider";

export const Rewards = () => {
	const { t } = useTranslation(["landing"]);
	const elementRef = useRef(null);
	const [animationPlayed, setAnimationPlayed] = useState<boolean>(false);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	const [accordionOpen, setAccordionOpen] = useState<boolean | null>(null);
	const groupRef = useRef<HTMLDivElement>(null);
	const { myStorageUrl } = useContext(FirebaseContext);

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	useEffect(() => {
		const panel = document.querySelector("#giftcard-image");
		const handleMouseMovement = (e) => {
			const rect_ = $("#giftcard-image").get(0).getBoundingClientRect();
			const mouseX = e.clientX - rect_.left;
			const mouseY = e.clientY - rect_.top;
			const element = $("#giftcard-image").get(0);
			element.style.setProperty("--mouse-x", `${mouseX}deg`);
			element.style.setProperty("--mouse-y", `${mouseY}deg`);
		};
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener("resize", handleResize);
		panel.addEventListener("mousemove", handleMouseMovement);

		return () => {
			panel.removeEventListener("mousemove", handleMouseMovement);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					/**Make sure that the timeout time is equal to the animation time */
					if (entry.isIntersecting) {
						if (!entry.target.classList.contains("appear-from-left-rewards")) {
							entry.target.classList.add("appear-from-left-rewards");
							setTimeout(() => {
								setAnimationPlayed(true);
							}, 2000);
							observer.unobserve(entry.target);
						}
					} else if (false) {
						if (entry.target.classList.contains("appear-from-left-rewards")) {
							entry.target.classList.add("disappear-from-left-rewards");
							entry.target.classList.remove("appear-from-left-rewards");
							setTimeout(() => {
								setAnimationPlayed(false);
								entry.target.classList.remove("disappear-from-left-rewards");
							}, 2000);
							observer.unobserve(entry.target);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (elementRef.current) {
			observer.observe(elementRef.current);
		}

		return () => {
			if (elementRef.current) {
				observer.unobserve(elementRef.current);
			}
		};
	}, [animationPlayed]);

	const [textAnimation, setTextAnimation] = useState<boolean>(false);
	const textContainerRef = useRef(null);
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					/**Make sure that the timeout time is equal to the animation time */
					if (entry.isIntersecting) {
						if (!entry.target.classList.contains("appear-from-bottom")) {
							entry.target.classList.add("appear-from-bottom");
							setTimeout(() => {
								setTextAnimation(true);
							}, 2000);
							observer.unobserve(entry.target);
						}
					} else if (false) {
						if (entry.target.classList.contains("appear-from-bottom")) {
							entry.target.classList.add("disappear-from-bottom");
							entry.target.classList.remove("appear-from-bottom");
							setTimeout(() => {
								setTextAnimation(false);
								entry.target.classList.remove("disappear-from-bottom");
							}, 2000);
							observer.unobserve(entry.target);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (textContainerRef.current) {
			observer.observe(textContainerRef.current);
		}

		return () => {
			if (textContainerRef.current) {
				observer.unobserve(textContainerRef.current);
			}
		};
	}, [textAnimation]);

	useEffect(() => {
		const accordionButton = document.getElementById("accordion-rewards-button");
		const group = groupRef.current;

		const layout = () => {
			group.classList.toggle("accordion-text-open");
		};
		accordionButton.addEventListener("click", layout);
		return () => {
			if (accordionButton) {
				accordionButton.removeEventListener("click", layout);
			}
		};
	}, []);

	const handleClick = () => {
		document.getElementById("landing-signup-container").scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			<Grid container id="rewards-container" justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={10}>
					<Grid container justifyContent="center" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
						<Grid
							item
							xs={10}
							sm={6}
							lg={7}
							xl={6}
							className="container-rewards-image"
							ref={elementRef}
							id="rewards-image-container"
							alignSelf="flex-start"
						>
							{windowDimensions.width < 1200 ? (
								<img
									id="giftcard-image"
									className="rewards-image"
									src={`${myStorageUrl}images/landing/giftcard_image.png`}
									alt="Depth Image"
								/>
							) : (
								<Gitfcard />
							)}
							<div id="rewards-first-bubble-background"></div>
							<div id="rewards-second-bubble-background"></div>
							<div id="rewards-third-bubble-background"></div>
							<div id="rewards-fourth-bubble-background"></div>
						</Grid>
						<Grid item xs={10} sm={6} lg={4} xl={6} id="rewards-text-container" ref={textContainerRef}>
							<Typography variant="h3" className="section-text">
								{t("rewards.title")}
							</Typography>
							<div className="container-rewards-call-to-action" id="container-rewards-call-to-action" ref={groupRef}>
								<Accordion
									id="accordion-rewards"
									accordionActionElement={<Typography className="accordion-text">{t("discover.more")}</Typography>}
									removeTitle={true}
									setAccordionOpen={setAccordionOpen}
									accordionOpen={accordionOpen}
								>
									<Typography className="accordion-content-text">{t("rewards.accordion")}</Typography>
								</Accordion>
								<button onClick={handleClick} className="button-landing  join-button">
									{t("button.join.us")}
								</button>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const Gitfcard = () => {
	const { t } = useTranslation("dashboard");

	const { t: landingT } = useTranslation("landing");

	const [countrySelected, setCountrySelected] = useState("Italy");
	const [countryId, setCountryId] = useState(4);
	const [amountSelected, setAmountSelected] = useState<number>(0);
	const { myStorageUrl } = useContext(FirebaseContext);

	const countries = [
		{ id: 0, name: "Australia" },
		{ id: 1, name: "Canada" },
		{ id: 2, name: "Netherlands" },
		{ id: 3, name: "India" },
		{ id: 4, name: "Italy" },
		{ id: 5, name: "Japan" },
		{ id: 6, name: "Mexico" },
		{ id: 7, name: "Philippines" },
		{ id: 8, name: "Poland" },
		{ id: 9, name: "Sweden" },
		{ id: 10, name: "UK" },
		{ id: 11, name: "USA" },
	];
	const TranslatedCountries = [
		{ id: 0, name: t("Australia") },
		{ id: 1, name: t("Canada") },
		{ id: 2, name: t("Netherlands") },
		{ id: 3, name: t("India") },
		{ id: 4, name: t("Italy") },
		{ id: 5, name: t("Japan") },
		{ id: 6, name: t("Mexico") },
		{ id: 7, name: t("Philippines") },
		{ id: 8, name: t("Poland") },
		{ id: 9, name: t("Sweden") },
		{ id: 10, name: t("UK") },
		{ id: 11, name: t("USA") },
	];
	const changeCountry = (nextValue: { id: number; name: string }): void => {
		setCountryId(nextValue.id);
		setCountrySelected(countries[nextValue.id].name);
	};
	const handlePurchase = () => {
		$("#giftcard-image").addClass("purchase-animation");
		setTimeout(() => {
			$("#giftcard-image").removeClass("purchase-animation");
		}, 2000);
	};

	return (
		<>
			<Grid container id="giftcard-image" className="rewards-image interactable" columns={16}>
				<Grid item xs={7}>
					<img src={`${myStorageUrl}images/landing/amazon_image.png`} alt="amazon image" id="amazon-image" />
				</Grid>
				<Grid item xs={9}>
					<Grid container sx={{ height: "100%" }} rowGap={2} p="12px">
						<Grid item xs={12}>
							<Typography variant="h5" fontFamily="sora" fontSize="30px" lineHeight="38px">
								<b>Amazon</b> gift card
							</Typography>
							<Typography fontFamily="sora" fontSize="12px">
								{landingT("rewards.amazon.description")}
							</Typography>
						</Grid>
						<Grid item xs={12} display="flex" gap={3} alignItems="center">
							<Typography fontFamily="sora" fontSize="12px">
								{t("country")}
							</Typography>
							<div className="market-giftcard-selection-rewards">
								<div className="countries-dropdown-rewards">
									<DropdownList
										value={t(countries[countryId].name)}
										dataKey="id"
										textField="name"
										onChange={(nextValue) => changeCountry(nextValue)}
										data={TranslatedCountries}
									/>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sx={{ display: "flex", alignItems: "center", width: "100%", gap: "8px" }}>
							<button
								className={`giftcard-button ${amountSelected === 0 ? "amount-selected" : ""}`}
								onClick={() => {
									setAmountSelected(0);
								}}
							>
								10€
							</button>
							<button
								className={`giftcard-button ${amountSelected === 1 ? "amount-selected" : ""}`}
								onClick={() => {
									setAmountSelected(1);
								}}
							>
								25€
							</button>
							<button
								className={`giftcard-button ${amountSelected === 2 ? "amount-selected" : ""}`}
								onClick={() => {
									setAmountSelected(2);
								}}
							>
								50€
							</button>
							<button
								className={`giftcard-button ${amountSelected === 3 ? "amount-selected" : ""}`}
								onClick={() => {
									setAmountSelected(3);
								}}
							>
								100€
							</button>
						</Grid>
						<Grid item xs={12} display="flex" justifyContent="flex-end">
							<button className="button-pay-giftcard" style={{ fontSize: "12px" }}>
								{landingT("rewards.buy.in.euro")}
							</button>
							<button className="button-pay-giftcard" style={{ fontSize: "12px" }} onClick={handlePurchase}>
								{landingT("rewards.buy.with.bolts")}
							</button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
