import { Typography } from "@mui/material"


const GradientTypography = ({ content, variant, gradientVersion, sx }: {
    content: string,
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | undefined,
    gradientVersion: 'v1' | 'v2',
    sx?: any
}) => {
    return (
        <>
            {
                gradientVersion === 'v1' &&
                <Typography sx={{
                    background: "linear-gradient(90deg, #ab00c7 0%, #0075ff 100.01%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    ...sx
                }} variant={variant}  >
                    {content}
                </Typography>
            }
            {
                gradientVersion === 'v2' &&
                <Typography sx={{
                    background: "linear-gradient(270deg, #7100A7 0%, #F07EFA 100%);",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    ...sx
                }} variant={variant}  >
                    {content}
                </Typography>
            }
        </>

    )
}

export default GradientTypography;