import { useContext, useEffect, useState } from "react";
import { Box, Grid, Skeleton } from "@mui/material";

import { LeaderboardContext } from "../../../../Providers/LeaderboardProvider";
import { LeaderItem } from "../LeaderItem";
import { PaginationLeaderboard } from "../PaginationLeaderboard";
import { useTranslation } from "react-i18next";
import { LeaderboardDotaProps } from "../../../../../types/Props";
import { gamesInStorm } from "../../../../../utilities/costants/genericCostants";
import { LoadingComponent } from "../../../../Loading/LoadingComponent";

export const LeaderboardDota = (props: LeaderboardDotaProps): JSX.Element => {
	const { profile, leaderboardToShow, style, loadPlayers, isLiveLeaderboard } = props;
	const [isLoadingPlayers, setIsLoadingPlayers] = useState<boolean>(false);

	const { maxNumberOfPlayersInLeaderboard, numberOfPlayersDota2, numberOfLivePlayersDota2, loggedUserInLeader, loggedUserInLiveLeader } =
		useContext(LeaderboardContext);
	const { t } = useTranslation("dashboard");
	return (
		<div className="leaderboard">
			{leaderboardToShow === undefined ? (
				<>
					<Skeleton variant={"rectangular"} width={"100%"} height={"200px"} sx={{ bgcolor: "grey.900" }}></Skeleton>
				</>
			) : (
				<>
					{isLoadingPlayers && (
						<>
							<div className="loading-players">
								<section className="loading-players-container">
									<LoadingComponent ended={!isLoadingPlayers} />
								</section>
							</div>
						</>
					)}

					<Grid container className="leaderboard-header">
						{isLiveLeaderboard ? (
							<>
								{loggedUserInLiveLeader !== undefined ? (
									<>
										{Object.keys(loggedUserInLiveLeader).length !== 0 && loggedUserInLiveLeader.game === gamesInStorm.dota && (
											<>
												<p>live {isLiveLeaderboard}</p>
												<h2 className="leaderboard-title">{t("your position")}</h2>
												<div className="pulse-animation-my-position">
													<LeaderItem
														player={loggedUserInLiveLeader}
														image={profile.imageUrl}
														username={profile.username}
														index={0}
														game={gamesInStorm.dota}
													/>
												</div>
											</>
										)}
									</>
								) : (
									<Skeleton variant={"rectangular"} width={"100%"} height={"1200px"} sx={{ bgcolor: "red" }}></Skeleton>
								)}
							</>
						) : (
							<>
								{loggedUserInLeader !== undefined ? (
									<>
										{Object.keys(loggedUserInLeader).length !== 0 && loggedUserInLeader.game === gamesInStorm.dota && (
											<>
												<h2 className="leaderboard-title">{t("your position")}</h2>
												<div className="pulse-animation-my-position">
													<LeaderItem
														player={loggedUserInLeader}
														image={profile.imageUrl}
														username={profile.username}
														index={0}
														game={gamesInStorm.dota}
													/>
												</div>
											</>
										)}
									</>
								) : (
									<Skeleton variant={"rectangular"} width={"100%"} height={"1200px"} sx={{ bgcolor: "grey.900" }}></Skeleton>
								)}
							</>
						)}

						<Grid container marginTop={"2rem"}>
							{/* POSITION */}
							<Grid item xs={1} md={1} lg={1}>
								#
							</Grid>

							{/* IMAGE */}
							<Grid item md={1} lg={1} display={{ xs: "none", md: "block", lg: "block" }}></Grid>

							{/* USERNAME */}
							<Grid item xs={5} md={5} lg={4}></Grid>

							{/* RANK */}
							<Grid item lg={2} display={{ xs: "none", md: "none", lg: "block" }}>
								{t("rank")}
							</Grid>

							{/* DAILY POINTS */}
							<Grid item xs={3} md={2} lg={2}>
								{t("daily points")}
							</Grid>

							{/* DAILY BOLTS */}
							<Grid item xs={3} md={3} lg={2}>
								{t("daily bolts")}
							</Grid>
						</Grid>
						<hr className="border-line" style={{ background: "grey", opacity: "0.3" }} />

						{leaderboardToShow}
						<Grid container>
							<Grid item xs={12}>
								<Grid container alignItems={"center"} justifyContent={"center"} direction="column">
									{isLiveLeaderboard ? (
										<>
											<PaginationLeaderboard
												numberOfPlayers={numberOfLivePlayersDota2}
												playersPerPage={maxNumberOfPlayersInLeaderboard}
												style={style}
												loadPlayers={loadPlayers}
												game={gamesInStorm.dota}
												isLiveLeaderboard={isLiveLeaderboard}
												setIsLoadingPlayers={setIsLoadingPlayers}
											/>
										</>
									) : (
										<PaginationLeaderboard
											numberOfPlayers={numberOfPlayersDota2}
											playersPerPage={maxNumberOfPlayersInLeaderboard}
											style={style}
											loadPlayers={loadPlayers}
											game={gamesInStorm.dota}
											isLiveLeaderboard={isLiveLeaderboard}
											setIsLoadingPlayers={setIsLoadingPlayers}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</div>
	);
};
