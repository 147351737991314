import { Box, SxProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export type ItemImageMarketCardProps = {
	src: string;
	alt: string;
	onClick?: any;
	sx?: SxProps<Theme>;
};

const style = makeStyles({
	root: {
		borderRadius: 10,
	},
});

export const ItemImageMarketCard = (props: ItemImageMarketCardProps) => {
	const classes = style();

	return <Box component={"img"} className={classes.root} src={props.src} alt={props.alt} sx={props.sx} />;
};
