import { useTranslation } from "react-i18next";
import { TeamMemberType } from "../types/Home/AboutUs/TeamMemberPopup";

export const GetTeamInformation = (): TeamMemberType[] => {
	const { t } = useTranslation("landing");
	const teamInformation: TeamMemberType[] = [
		{
			name: "Alessio di Dio",
			role: "CEO & Co-Founder",
			description: t("didio.description"),
			image: "images/team_pictures/new_landing_alessio_di_dio.png",
			favouriteGame: "Dota 2",
			link: [
				{
					name: "eMail",
					url: "mailto:didio@storm.co.gg",
				},
			],
		},
		{
			name: "Ruben Soro",
			role: "CEO & Co-Founder",
			description: t("soro.description"),
			image: "images/team_pictures/new_landing_ruben_soro.png",
			favouriteGame: "Fortnite",
			link: [
				{
					name: "LinkedIn",
					url: "https://www.linkedin.com/in/ruben-s-8a3b82100/",
				},
				{
					name: "eMail",
					url: "mailto:soro@storm.co.gg",
				},
			],
		},
		{
			name: "Lorenzo Gilardi",
			role: "Full Stack Developer",
			image: "images/team_pictures/new_landing_lorenzo_gilardi.png",
			favouriteGame: "Dota 2",
			link: [
				{
					name: "LinkedIn",
					url: "https://www.linkedin.com/in/lorenzo-gilardi-9b232127a/",
				},
				{
					name: "eMail",
					url: "mailto:gilardi@storm.co.gg",
				},
			],
			description: t("gilardi.description"),
		},
		{
			name: "Nicolò Vanzo",
			role: "Full Stack Developer",
			image: "images/team_pictures/new_landing_nicolo_vanzo.jpeg",
			favouriteGame: "Fortnite",
			link: [
				{
					name: "eMail",
					url: "mailto:soro@storm.co.gg",
				},
			],
			description: t("vanzo.description"),
		},
		{
			name: "Stefano Cipolletta",
			role: "Full Stack Developer",
			image: "images/team_pictures/new_landing_stefano_cipolletta.png",
			favouriteGame: "Fortnite",
			link: [
				{
					name: "eMail",
					url: "mailto:soro@storm.co.gg",
				},
			],
			description: t("cipolletta.description"),
		},
		{
			name: "Francesco Ritota",
			role: "Community Manager",
			image: "images/team_pictures/new_landing_francesco_ritota.png",
			favouriteGame: "Fortnite",
			link: [
				{
					name: "eMail",
					url: "mailto:soro@storm.co.gg",
				},
			],
			description: t("ritota.description"),
		},
	];

	return teamInformation;
};